import React from 'react';

import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';

import '../../css/Compass.css';

const LocationTroubleShooting = () => {
  return (
    <div className="troubleshooting-container">
      <h2 className="troubleshooting-title">Location Detection Troubleshooting Guide</h2>
      <p className="troubleshooting-intro">
        If your location detection isn't working correctly, try the following steps to
        resolve the issue:
      </p>
      <ol className="troubleshooting-steps">
        <li>
          <strong>Ensure Location Services Are Enabled:</strong> Go to{' '}
          <em>Settings &gt; Privacy &gt; Location Services</em> and make sure location
          services are turned on. Verify that Safari or your browser has location
          permissions set to "Allow While Using the App."
        </li>
        <li>
          <strong>Check Website Settings:</strong> Open Safari, go to your web app, tap
          the <em>aA</em> icon in the address bar, and select <em>Website Settings</em>.
          Ensure <strong>Location</strong> is set to "Allow."
        </li>
        <li>
          <strong>Use a Secure HTTPS Connection:</strong> Geolocation requests require a
          secure connection. Make sure the website is using <strong>https://</strong>.
        </li>
        <li>
          <strong>Clear Website Data:</strong> If the geolocation request was previously
          denied, clearing the website data might help. Go to{' '}
          <em>Settings &gt; Safari &gt; Clear History and Website Data</em>.
        </li>
        <li>
          <strong>Check Content & Privacy Restrictions:</strong> Go to{' '}
          <em>
            Settings &gt; Screen Time &gt; Content & Privacy Restrictions &gt; Location
            Services
          </em>{' '}
          and make sure location access is allowed for Safari.
        </li>
        <li>
          <strong>Restart Safari or Your Device:</strong> Force quit Safari or restart
          your device to clear any cached permission settings.
        </li>
        <li>
          <strong>Try a Different Browser or Private Mode:</strong> Opening the app in a
          different browser or in private browsing mode might reset the geolocation
          prompt.
        </li>
      </ol>
      <p className="troubleshooting-outro">
        If you continue to experience issues, ensure your device has a stable internet
        connection and that location detection is supported by your browser.
      </p>
    </div>
  );
};

export default function LocationTroubleShootingModal({
  darkTheme,
  sunType,
  isOpen,
  onClose,
}) {
  const { isModalOpen, closeModal } = useModal();

  return (
    <Modal
      show={isOpen ? isOpen : isModalOpen(ModalTypes.LocationTroubleShooting)}
      darkTheme={darkTheme}
      sunType={sunType}
      onClose={onClose ? onClose : closeModal}
    >
      <LocationTroubleShooting darkTheme={darkTheme} />
    </Modal>
  );
}
