import React from 'react';
import { getLineCoords } from '../utils/math';
import { SunTypes } from '../components/sun_path';
import { CircadianEventType } from '../components/circadian_event';

import '../css/SunPath.css';

function interpolateColor(color1, color2, factor) {
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}

function rgbToString(rgb) {
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
}

function calculateColorDifference(color1, color2) {
  return color1.map((component, index) => component - color2[index]);
}

function applyColorDifference(baseColor, difference, factor) {
  return baseColor.map((component, index) =>
    Math.max(0, Math.min(255, Math.round(component + factor * difference[index]))),
  );
}

function getNextColor(startColor, endColor, factor) {
  const colorDifference = calculateColorDifference(startColor, endColor);
  return applyColorDifference(endColor, colorDifference, factor);
}

const SunSvgIcon = ({
  sunRadius,
  spikeLength = 15,
  spikePattern = 'even',
  clipHeight,
  sunColorStart,
  sunColorEnd,
  spikeColor,
  onClick,
}) => {
  const centerX = 100;
  const centerY = 100;

  const isRisePattern = spikePattern === 'rise';
  const upperSpikeLength = isRisePattern ? spikeLength * 3 : spikeLength;
  const lowerSpikeLength = spikeLength;
  const longUpperSpikeLength = upperSpikeLength * 2;
  const longLowerSpikeLength = lowerSpikeLength * 2;
  const lowerDiagonalSpikeLength = lowerSpikeLength * 1.5;
  const upperDiagonalSpikeLength = upperSpikeLength * 1.5;
  const sideSpikeLength = upperDiagonalSpikeLength;
  //   isRisePattern
  // ? longUpperSpikeLength * 1.125
  // : longUpperSpikeLength;

  // Adjusted spike lengths for symmetry
  // 0 is 90 degrees
  const adjustedSpikes = [
    { angle: 0, length: sideSpikeLength },
    { angle: 22.5, length: lowerSpikeLength },
    { angle: 45, length: lowerDiagonalSpikeLength },
    { angle: 67.5, length: lowerSpikeLength },
    { angle: 90, length: longLowerSpikeLength },
    { angle: 112.5, length: lowerSpikeLength },
    { angle: 135, length: lowerDiagonalSpikeLength },
    { angle: 157.5, length: lowerSpikeLength },
    { angle: 180, length: sideSpikeLength },
    { angle: 202.5, length: upperSpikeLength },
    { angle: 225, length: upperDiagonalSpikeLength },
    { angle: 247.5, length: upperSpikeLength },
    { angle: 270, length: longUpperSpikeLength },
    { angle: 292.5, length: upperSpikeLength },
    { angle: 315, length: upperDiagonalSpikeLength },
    { angle: 337.5, length: upperSpikeLength },
  ];

  const maxSpikeLength = Math.max(...adjustedSpikes.map((spike) => spike.length));

  function generateGradient(id, colorStart, colorEnd) {
    return (
      <linearGradient id={id} key={id} gradientUnits="userSpaceOnUse">
        <stop offset="0%" stopColor={colorStart} />
        <stop offset="100%" stopColor={colorEnd} />
      </linearGradient>
    );
  }

  function calculatePolygonPoints(angle, length, innerRadius, gap, centerX, centerY) {
    const angleRad = (Math.PI / 180) * angle;
    const outerRadius = innerRadius + gap + length; // Distance from center to outer point
    const innerRadiusWithGap = innerRadius + gap; // Base of spike starts after gap

    const outerX = centerX + outerRadius * Math.cos(angleRad);
    const outerY = centerY + outerRadius * Math.sin(angleRad);

    const spikeWidthAngle = (Math.PI / 180) * 1.5; // Narrow spike width (~1.5 degrees)
    const leftAngleRad = angleRad - spikeWidthAngle;
    const rightAngleRad = angleRad + spikeWidthAngle;

    const innerLeftX = centerX + innerRadiusWithGap * Math.cos(leftAngleRad);
    const innerLeftY = centerY + innerRadiusWithGap * Math.sin(leftAngleRad);
    const innerRightX = centerX + innerRadiusWithGap * Math.cos(rightAngleRad);
    const innerRightY = centerY + innerRadiusWithGap * Math.sin(rightAngleRad);

    return `${innerLeftX},${innerLeftY} ${outerX},${outerY} ${innerRightX},${innerRightY}`;
  }

  const gapBetweenSunAndSpikes = 4; // Increased gap from 2 to 4

  return (
    <svg
      width={sunRadius * 2 * 2}
      viewBox={`0 0 200 200`}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        overflow: 'visible',
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <defs>
        <radialGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="20%">
          <stop
            offset="0%"
            style={{
              stopColor: rgbToString(sunColorStart),
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: rgbToString(sunColorEnd),
              stopOpacity: 1,
            }}
          />
        </radialGradient>
        {adjustedSpikes.map((spike, index) => {
          const lengthRatio = spike.length / maxSpikeLength;
          const endSpikeColor = getNextColor(sunColorStart, sunColorEnd, lengthRatio);
          const gradientId = `grad-${index}`;
          return generateGradient(
            gradientId,
            rgbToString(sunColorEnd),
            rgbToString(endSpikeColor),
          );
        })}
        {clipHeight && (
          <clipPath id="clip">
            <rect x="0" y="0" width="200" height={clipHeight} />
          </clipPath>
        )}
      </defs>
      <g clipPath={clipHeight ? 'url(#clip)' : undefined}>
        <circle cx={centerX} cy={centerY} r={sunRadius} fill="url(#grad1)" />
        <circle
          cx={centerX}
          cy={centerY}
          r={sunRadius}
          fill="none"
          stroke={sunColorEnd}
          strokeWidth="2"
        />
        <g>
          {adjustedSpikes.map((spike, index) => {
            const points = calculatePolygonPoints(
              spike.angle,
              spike.length,
              sunRadius,
              gapBetweenSunAndSpikes,
              centerX,
              centerY,
            );
            const gradientId = `url(#grad-${index})`;
            return (
              <polygon key={index} points={points} fill={gradientId} stroke="none" />
            );
          })}
        </g>
      </g>
      {onClick && (
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="middle"
          className="more-info-sun"
        >
          <tspan x={centerX} dy="-0.6em">
            More
          </tspan>
          <tspan x={centerX} dy="1.2em">
            Info
          </tspan>
        </text>
      )}
    </svg>
  );
};

export default SunSvgIcon;

const SunriseSpikeLength = 15;
const UVASpikeLength = 25;
const UVBSpikeLength = 35;
const SolarNoonSpikeLength = 45;

const SpikeLengths = {
  [SunTypes.Red]: SunriseSpikeLength,
  [SunTypes.UVA]: UVASpikeLength,
  [SunTypes.UVB]: UVBSpikeLength,
  [SunTypes.SolarNoon]: SolarNoonSpikeLength,
};

export const SunriseSun = ({ radius = 60, progress = 0, nextSunType, onClick }) => {
  return (
    <SunSvgIcon
      sunRadius={radius}
      spikeLength={SunriseSpikeLength}
      spikePattern="rise"
      sunColorStart={[255, 94, 77]}
      sunColorEnd={[255, 165, 0]}
      spikeColor="orange"
      onClick={onClick}
    />
  );
};

function calculateSpikeLength(currentSpikeLength, nextSpikeLength, progress) {
  const spikeLength =
    currentSpikeLength + (progress * (nextSpikeLength - currentSpikeLength)) / 100;
  return spikeLength;
}

function getSpikeLength(nextSunType) {
  if (nextSunType === CircadianEventType.Sunrise) {
    return UVASpikeLength;
  }
  if (nextSunType === CircadianEventType.UvaRise) {
    return UVBSpikeLength;
  }
  if (nextSunType === CircadianEventType.UvbRise) {
    return SolarNoonSpikeLength;
  }
  if (nextSunType === CircadianEventType.SolarNoon) {
    return UVBSpikeLength;
  }
  if (nextSunType === CircadianEventType.UvbSet) {
    return UVASpikeLength;
  }
  if (nextSunType === CircadianEventType.UvaSet) {
    return SunriseSpikeLength;
  }
  return 17.5;
}

export const UVASun = ({ progress = 0, nextSunType, onClick, radius = 60 }) => {
  const spikeLength = calculateSpikeLength(
    UVASpikeLength,
    getSpikeLength(nextSunType),
    progress,
  );
  return (
    <SunSvgIcon
      sunRadius={radius}
      spikeLength={spikeLength}
      sunColorStart={[255, 165, 0]}
      sunColorEnd={[255, 223, 0]}
      spikeColor="yellow"
      onClick={onClick}
    />
  );
};

export const UVBSun = ({ progress = 0, nextSunType, onClick, radius = 60 }) => {
  const spikeLength = calculateSpikeLength(
    UVBSpikeLength,
    getSpikeLength(nextSunType),
    progress,
  );
  return (
    <SunSvgIcon
      sunRadius={radius}
      spikeLength={spikeLength}
      sunColorStart={[255, 223, 0]}
      sunColorEnd={[255, 248, 145]}
      spikeColor="yellow"
      onClick={onClick}
    />
  );
};

export const SolarNoonSun = ({ progress = 0, nextSunType, onClick, radius = 60 }) => {
  const spikeLength = calculateSpikeLength(
    SolarNoonSpikeLength,
    getSpikeLength(nextSunType),
    progress,
  );
  return (
    <SunSvgIcon
      sunRadius={radius}
      spikeLength={spikeLength}
      sunColorStart={[255, 248, 145]}
      sunColorEnd={[255, 255, 0]}
      spikeColor="yellow"
      onClick={onClick}
    />
  );
};
