import React, { useEffect, useMemo, useState } from 'react';
import CompassSvg from '../icons/compass-svg';
import AppHeader from '../components/app_header';
import { useBackground } from '../hooks/use-background-animation';
import { ModalTypes, useModal } from '../providers/modal-provider';
import { useSolarClock } from '../providers/solar-clock-provider';
import { getSunType } from '../domain/sun-type';
import useBrowserInfo from '../hooks/use-browser-info';
import SunIcon, { isSunIcon } from '../icons/sun_icon';
import CompassNavigation from '../components/compass-nav';
import '../css/Compass.css';
import MoonPhase from '../icons/moon-svg';
import { OrientationProvider, useOrientation } from '../providers/orientation-provider';
import DefaultModal from '../components/modals/default-modal';
import SiteNav from '../components/site-nav';
import { usePage } from '../providers/page-provider';
import App from '../App';
import { UvProvider } from '../providers/uv-provider';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import LocationPage from './location-page';

const Directions = {
  North: 0,
  Northeast: Math.PI / 4,
  East: Math.PI / 2,
  Southeast: (3 * Math.PI) / 4,
  South: Math.PI,
  Southwest: (5 * Math.PI) / 4,
  West: (3 * Math.PI) / 2,
  Northwest: (7 * Math.PI) / 4,
};

function determineDirectionFromDegrees(azimuth) {
  let direction;

  console.log('Determining direction for azimuth', azimuth);
  if (azimuth >= 348.75 || azimuth < 11.25) {
    direction = 'North';
  } else if (azimuth >= 11.25 && azimuth < 33.75) {
    direction = 'North by Northeast';
  } else if (azimuth >= 33.75 && azimuth < 56.25) {
    direction = 'Northeast';
  } else if (azimuth >= 56.25 && azimuth < 78.75) {
    direction = 'East by Northeast';
  } else if (azimuth >= 78.75 && azimuth < 101.25) {
    direction = 'East';
  } else if (azimuth >= 101.25 && azimuth < 123.75) {
    direction = 'East by Southeast';
  } else if (azimuth >= 123.75 && azimuth < 146.25) {
    direction = 'Southeast';
  } else if (azimuth >= 146.25 && azimuth < 168.75) {
    direction = 'South by Southeast';
  } else if (azimuth >= 168.75 && azimuth < 191.25) {
    direction = 'South';
  } else if (azimuth >= 191.25 && azimuth < 213.75) {
    direction = 'South by Southwest';
  } else if (azimuth >= 213.75 && azimuth < 236.25) {
    direction = 'Southwest';
  } else if (azimuth >= 236.25 && azimuth < 258.75) {
    direction = 'West by Southwest';
  } else if (azimuth >= 258.75 && azimuth < 281.25) {
    direction = 'West';
  } else if (azimuth >= 281.25 && azimuth < 303.75) {
    direction = 'West by Northwest';
  } else if (azimuth >= 303.75 && azimuth < 326.25) {
    direction = 'Northwest';
  } else if (azimuth >= 326.25 && azimuth < 348.75) {
    direction = 'North by Northwest';
  }
  console.log('Direction determined', direction);
  return direction;
}

function describePosition(azimuth, body = 'sun', isFuture = false) {
  let direction = determineDirectionFromDegrees(azimuth);
  if (!direction) {
    return 'Invalid azimuth value. Please provide a value between 0 and 360.';
  }

  const verb = isFuture ? 'will be' : 'is';
  return `The ${body} ${verb} at ${azimuth} degrees ${direction.toLowerCase()}.`;
}

function convertRadiansToDegrees(moonRadians) {
  return Math.round((moonRadians * 180) / Math.PI);
}

function getRadiansFromAstronomicalPosition(azimuth) {
  return (azimuth + Math.PI) % (2 * Math.PI);
}

function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}
function CompassPage({ onLoad, isLoaded }) {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const {
    azimuth,
    isCompassAvailable,
    isPermissionRequired,
    requestPermission,
    isPermissionGranted,
    absolute,
  } = useOrientation();
  const { debug } = usePage();
  console.log('azimuth', azimuth);
  console.log('absolute', absolute);
  const { displayDate, moonPhase, sunPosition, moonPosition, sunTimes, isNight } =
    useSolarClock();
  const isSunVisible = useMemo(
    () => sunPosition.altitude > -0.05,
    [sunPosition.altitude],
  );
  const isMoonVisible = useMemo(
    () => moonPosition.altitude >= 0,
    [moonPosition.altitude],
  );
  const { openModal } = useModal();
  const { isMobileLayout } = useBrowserInfo();

  const sunType = useMemo(() => {
    return getSunType(sunTimes, displayDate);
  }, [sunTimes, displayDate]);

  const isDarkTheme = useMemo(() => {
    return isNight(displayDate);
  }, [displayDate]);
  const contentClass = isDarkTheme ? 'dark-mode-text-color' : '';

  const compassDegrees = azimuth == null ? 0 : azimuth;
  const compassRadians = degreesToRadians(compassDegrees);

  console.log('sunAzimuth', sunPosition.azimuth);
  console.log('sunAltitude', sunPosition.altitude);
  console.log('moonAzimuth', moonPosition.azimuth);
  console.log('moonAltitude', moonPosition.altitude);
  let sunRadians = getRadiansFromAstronomicalPosition(sunPosition.azimuth); // Adjust SunCalc azimuth so 0 radians is North
  const sunDegrees = convertRadiansToDegrees(sunRadians);
  const SunRadius = 35;
  const SunComponent = isSunVisible
    ? () => <SunIcon radius={SunRadius} sunType={sunType} isMobile={isMobileLayout} />
    : null;
  console.log('sunRadians', sunRadians);
  console.log('sunDegrees', sunDegrees);

  let moonRadians = getRadiansFromAstronomicalPosition(moonPosition.azimuth); // Adjust SunCalc azimuth so 0 radians is North
  const moonDegrees = convertRadiansToDegrees(moonRadians);
  const MoonRadius = 15;
  const MoonComponent = isMoonVisible
    ? () => <MoonPhase radius={MoonRadius} phase={moonPhase} />
    : null;

  useBackground(sunType);

  const { startOrientationListener, stopOrientationListener } = useOrientation();
  useEffect(() => {
    startOrientationListener();

    return () => stopOrientationListener();
  }, [startOrientationListener, stopOrientationListener]);

  const onPageLoad = () => {
    onLoad();
  };

  useEffect(() => {
    if (!isLoaded) {
      onPageLoad();
    }
  }, []);

  useEffect(() => {
    if (!isCompassAvailable) {
      setIsAlertOpen(true);
    }
  }, [isCompassAvailable]);

  const onCloseAlert = () => setIsAlertOpen(false);
  const activateCompass = useMemo(() => {
    return (
      <div>
        <div>Your device requires permission to active the compass.</div>
        <button className="gradient-button button-small" onClick={requestPermission}>
          Activate Compass
        </button>
      </div>
    );
  }, [isPermissionRequired, requestPermission, isPermissionGranted]);

  return (
    <div className="App">
      <AppHeader darkTheme={!isSunIcon(sunType)} sunType={sunType} isLoaded={isLoaded} />
      <SiteNav darkTheme={isDarkTheme} isLoaded={!isLoaded} sunType={sunType} />
      <CompassNavigation
        darkTheme={isDarkTheme}
        isLocationLoading={!isLoaded}
        sunType={sunType}
      />
      <div className={contentClass}>
        <div className="orientation-data">
          {isPermissionRequired && !isPermissionGranted && (
            <DefaultModal
              darkTheme={isDarkTheme}
              sunType={sunType}
              onClose={onCloseAlert}
              isOpen={isAlertOpen}
              content={activateCompass}
              autoHeight
            />
          )}
          {debug && (
            <div>
              Is Compass Absolute? {absolute.toString()} <br />
              Compass Position: {compassRadians?.toFixed(2)}, {compassDegrees} degrees,{' '}
              {determineDirectionFromDegrees(compassDegrees)} <br />
              Sun Position: {sunRadians?.toFixed(2)}, {sunDegrees} degrees,{' '}
              {determineDirectionFromDegrees(sunDegrees)} <br />
              Moon Position: {moonRadians?.toFixed(2)}, {moonDegrees} degrees,{' '}
              {determineDirectionFromDegrees(moonDegrees)} <br />
            </div>
          )}
          {!isCompassAvailable && (
            <div>
              <strong>
                It looks like your device doesn't have a compass, or the sensors aren't
                functioning properly. Check out the &nbsp;
                <span
                  className="link"
                  onClick={() => openModal(ModalTypes.CompassTroubleShooting)}
                >
                  troubleshooting page
                </span>{' '}
                for more information.
              </strong>
            </div>
          )}
        </div>
        <div className="page-content">
          <CompassSvg
            azimuth={compassDegrees}
            sunRadians={sunRadians}
            SunComponent={SunComponent}
            sunRadius={SunRadius * 4}
            moonRadians={moonRadians}
            MoonComponent={MoonComponent}
            moonRadius={MoonRadius}
            darkMode={isDarkTheme}
          />
        </div>
        <div>
          {' '}
          {isSunVisible && (
            <div>
              <strong>{describePosition(sunDegrees, 'Sun')}</strong>
            </div>
          )}
          {isMoonVisible && (
            <div>
              <strong>{describePosition(moonDegrees, 'Moon')}</strong>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const CompassPageWrapper = ({ onLoad, isLoaded }) => {
  const isFeatureActive = useFeature();

  return (
    <LocationPage>
      <OrientationProvider
        useFakeOrientation={isFeatureActive(FeatureFlags.IsManualCompassEnabled)}
      >
        <CompassPage onLoad={onLoad} isLoaded={isLoaded} />;
      </OrientationProvider>
    </LocationPage>
  );
};

export default CompassPageWrapper;
