import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

const Directions = {
  N: 0,
  NE: 45,
  E: 90,
  SE: 135,
  S: 180,
  SW: 225,
  W: 270,
  NW: 315,
};

const OrientationContext = createContext();

export const OrientationProvider = ({ children, useFakeOrientation = false }) => {
  const [azimuth, setAzimuth] = useState(undefined);
  const [absolute, setAbsolute] = useState(false); // Added absolute state
  const [isCompassAvailable, setIsCompassAvailable] = useState(true);
  const alphaNullCount = useRef(0);

  const isIOS = useMemo(() => {
    return (
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/)
    );
  }, []);

  const isPermissionRequired = useMemo(() => {
    return typeof DeviceOrientationEvent.requestPermission === 'function';
  }, []);
  const [isPermissionGranted, setIsPermissionGranted] = useState(!isPermissionRequired);

  const throttleTimeout = useRef(null);

  function clearThrottle() {
    clearTimeout(throttleTimeout.current);
    throttleTimeout.current = null;
  }

  const handleOrientation = useCallback(
    (event) => {
      if (!throttleTimeout.current) {
        throttleTimeout.current = setTimeout(() => {
          console.log('e.webkitCompassHeading', event.webkitCompassHeading);
          const newAzimuth = event.webkitCompassHeading || event.alpha;
          const newAbsolute = event.absolute ?? false;

          setAzimuth(newAzimuth);
          setAbsolute(newAbsolute);

          if (event.alpha === null) {
            alphaNullCount.current = alphaNullCount.current + 1;
          } else {
            alphaNullCount.current = 0;
            setIsCompassAvailable(true);
          }

          if (alphaNullCount.current >= 15) {
            setIsCompassAvailable(false);
          }

          clearThrottle();
        }, 200);
      }
    },
    [setAzimuth, setAbsolute, setIsCompassAvailable],
  );

  const requestPermission = useCallback(async () => {
    if (isIOS && isPermissionRequired) {
      try {
        const response = await DeviceOrientationEvent.requestPermission();
        if (response === 'granted') {
          window.addEventListener('deviceorientation', handleOrientation, true);
          setIsPermissionGranted(true);
        } else {
          alert('Permission has to be allowed to access device orientation.');
        }
      } catch (error) {
        alert('Device orientation not supported or permission denied.');
      }
    } else if (!isIOS) {
      // Non-iOS or versions without explicit permission requirement
      if (window.DeviceOrientationEvent) {
        if ('ondeviceorientationabsolute' in window) {
          window.addEventListener('deviceorientationabsolute', handleOrientation, true);
        } else {
          window.addEventListener('deviceorientation', handleOrientation, true);
        }
      }
    }
  }, [isIOS, isPermissionRequired, handleOrientation]);

  const resetCompass = () => {
    alphaNullCount.current = 0;
    setIsCompassAvailable(true);
  };

  const startOrientationListener = useCallback(() => {
    if (useFakeOrientation) {
      let currentAzimuth = 0; //Math.round(Math.random() * 360);
      setAzimuth(currentAzimuth);

      const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp') {
          // Clockwise
          setAzimuth((prevAzimuth) => (prevAzimuth + 1) % 360);
        } else if (event.key === 'ArrowDown') {
          // Counterclockwise
          setAzimuth((prevAzimuth) => (prevAzimuth - 1 + 360) % 360);
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    } else {
      void requestPermission();
    }
  }, [useFakeOrientation, requestPermission]);

  useEffect(() => {
    startOrientationListener();
    return () => {
      stopOrientationListener();
    };
  }, [startOrientationListener]);

  useEffect(() => {
    console.log('azimuth changed', azimuth);
  }, [azimuth]);

  const stopOrientationListener = useCallback(() => {
    window.removeEventListener('deviceorientation', handleOrientation);
    window.removeEventListener('deviceorientationabsolute', handleOrientation);
    if (throttleTimeout.current) {
      clearThrottle();
    }
  }, [handleOrientation]);

  return (
    <OrientationContext.Provider
      value={{
        azimuth,
        absolute, // Added absolute value to the context
        isCompassAvailable,
        isPermissionRequired,
        requestPermission,
        isPermissionGranted,
        resetCompass,
        startOrientationListener,
        stopOrientationListener,
      }}
    >
      {children}
    </OrientationContext.Provider>
  );
};

export const useOrientation = () => {
  return useContext(OrientationContext);
};
