import {
  CircadianEventType,
  generateCircadianEvents,
} from '../components/circadian_event';
import {
  calculateCoordinates,
  calculateSunpathDegrees,
  getLineCoords,
  timeToDegrees,
} from '../utils/math';
import { SunTypes } from '../components/sun_path';
import { useMemo } from 'react';
import { getSunType } from '../domain/sun-type';

function calculateDashArray(riseDegrees = 0, setDegrees = 0) {
  if (riseDegrees === setDegrees) {
    return { activeDashArray: '0 0', defaultDashArray: '0 0 0 360' };
  }
  // Calculate the point on the circle where the icon should be placed
  const defaultLength = riseDegrees - setDegrees;
  const activeLength = 360 - defaultLength;

  const activeDashArray = `${defaultLength} ${activeLength}`; // Stroke dash pattern for the outer circle
  const defaultDashArray = `0 ${defaultLength} ${activeLength} ${activeLength}`; //"0 180 180 180";  // Stroke dash pattern for the inner circle
  return { defaultDashArray, activeDashArray };
}

// Function to normalize degrees to 0-360
function normalizeDegrees(degrees) {
  return (degrees + 360) % 360;
}

// Function to calculate counterclockwise difference
function counterclockwiseDifference(from, to) {
  return (to - from + 360) % 360;
}

function clockwiseDifference(from, to) {
  return (from - to + 360) % 360;
}

function findClosestCounterclockwise(currentDegrees, degreeMap) {
  let closestDegree = null;
  let minDifference = Infinity;

  for (const degree of Object.values(degreeMap)) {
    const normalizedCurrent = normalizeDegrees(currentDegrees);
    const normalizedDegree = normalizeDegrees(degree);
    const difference = counterclockwiseDifference(normalizedCurrent, normalizedDegree);

    if (difference < minDifference) {
      minDifference = difference;
      closestDegree = degree;
    }
  }

  return closestDegree;
}

function findClosestClockwise(currentDegrees, degreeMap) {
  let closestDegree = null;
  let minDifference = Infinity;

  for (const degree of Object.values(degreeMap)) {
    const normalizedCurrent = normalizeDegrees(currentDegrees);
    const normalizedDegree = normalizeDegrees(degree);
    const difference = clockwiseDifference(normalizedCurrent, normalizedDegree);

    if (difference < minDifference) {
      minDifference = difference;
      closestDegree = degree;
    }
  }

  return closestDegree;
}

function getEventMarkers(currentDegrees, degreeMaps) {
  let activeDegrees;
  if (currentDegrees >= 0 && currentDegrees < 180) {
    activeDegrees = findClosestCounterclockwise(currentDegrees, degreeMaps);
  } else {
    activeDegrees = findClosestClockwise(currentDegrees, degreeMaps);
  }

  const eventDegrees = Array.from(degreeMaps.values()).map((degree) => ({
    angle: degree,
    length: 10,
  }));

  // Clone eventDegrees to avoid modifying the original array
  const clonedEventDegrees = eventDegrees.slice();

  const index = clonedEventDegrees.findIndex(
    (event) => normalizeDegrees(event.angle) === normalizeDegrees(activeDegrees),
  );

  if (index !== -1) {
    const activeEvent = clonedEventDegrees[index];

    const normalizedActiveAngle = normalizeDegrees(activeEvent.angle);

    const activeEvents = clonedEventDegrees.filter((event) => {
      const normalizedEventAngle = normalizeDegrees(event.angle);
      if (normalizedActiveAngle < 180) {
        return (
          normalizedEventAngle <= normalizedActiveAngle ||
          normalizedEventAngle >= 360 - normalizedActiveAngle + 1
        );
      } else {
        return (
          normalizedEventAngle >= normalizedActiveAngle ||
          normalizedEventAngle <= 360 - normalizedActiveAngle + 1
        );
      }
    });

    // Remove the active events from clonedEventDegrees
    const remainingEventDegrees = clonedEventDegrees.filter(
      (event) => !activeEvents.includes(event),
    );

    // Combine the two parts to form the complete range including the other side of the circle
    const allActiveEvents = [activeEvent].concat(activeEvents);

    return {
      activeDegrees: allActiveEvents,
      eventDegrees: remainingEventDegrees,
    };
  }
  return { activeDegrees: [], eventDegrees: clonedEventDegrees };
}

const LabelOffset = -32;
const SunOffset = 60;
const SetDateXOffset = -68;
const RiseDateXOffset = 5;
const DateYOffset = 5;
const MavUVBRiseXCoord = -115.6;
const MaxUVBYCoord = -237;
const MinUVBSetXCoord = 48.59;
const MinDaybreakXCoord = -234.8;
const MaxDayYCoord = 163.8;
const MaxNightFallXCoord = 170.8;

function calculateDateCoords(degrees, radius, xOffset = 0, yOffset = 0) {
  // toDo -  10 is the clock mark length. 3 is the padding length. We should not hard code
  const { x1, y1, x2, y2 } = getLineCoords(
    degrees,
    10,
    radius - 10 - 3,
    xOffset,
    yOffset,
    3,
  );
  return { x: x1, y: y1 };
}

function findClosestDegreeEntries(currentDegrees, degreeMaps) {
  let closestLower = null;
  let closestHigher = null;

  for (const [key, value] of degreeMaps) {
    if (value <= currentDegrees) {
      if (closestLower === null || value > degreeMaps.get(closestLower)) {
        closestLower = key;
      }
    }
    if (value >= currentDegrees) {
      if (closestHigher === null || value < degreeMaps.get(closestHigher)) {
        closestHigher = key;
      }
    }
  }

  if (closestHigher == null) {
    closestHigher = CircadianEventType.SolarNoon;
  }

  return {
    startDegreeEntry: closestLower
      ? { key: closestLower, value: degreeMaps.get(closestLower) }
      : null,
    endDegreeEntry: closestHigher
      ? { key: closestHigher, value: degreeMaps.get(closestHigher) }
      : null,
  };
}

function calculateSunProgress(currentDegrees, degreeMaps) {
  const { startDegreeEntry, endDegreeEntry } = findClosestDegreeEntries(
    currentDegrees,
    degreeMaps,
  );
  const startDegrees = startDegreeEntry.value;
  const endDegrees = endDegreeEntry.value === 0 ? 360 : endDegreeEntry.value;

  if (currentDegrees < startDegrees || currentDegrees > endDegrees) {
    throw new Error('currentDegrees should be between startDegrees and endDegrees');
  }

  if (startDegrees === endDegrees) {
    return 100;
  }

  const sunProgress =
    ((currentDegrees - startDegrees) / (endDegrees - startDegrees)) * 100;

  if (isNaN(sunProgress)) {
    return 100;
  }
  return sunProgress;
}

export const generateSunPath = (sunTimes, sunElevationTime, radius) => {
  const {
    sunrise,
    sunset,
    dawn,
    dusk,
    uvaRise,
    uvaSet,
    uvbRise,
    uvbSet,
    solarNoon,
    nightfall,
    daybreak,
    nadir,
  } = sunTimes;

  const sunType = getSunType(sunTimes, sunElevationTime);

  const circadianEvents = generateCircadianEvents(sunTimes);
  const circadianEventExists = (eventType) =>
    circadianEvents.some((e) => e.label === eventType);

  const daybreakExists = circadianEventExists(CircadianEventType.Daybreak);
  const dawnExists = circadianEventExists(CircadianEventType.Dawn);
  const sunriseExists = circadianEventExists(CircadianEventType.Sunrise);
  const uvaRiseExists = circadianEventExists(CircadianEventType.UvaRise);
  const uvbRiseExists = circadianEventExists(CircadianEventType.UvbRise);
  const uvbSetExists = circadianEventExists(CircadianEventType.UvbSet);
  const uvaSetExists = circadianEventExists(CircadianEventType.UvaSet);
  const sunsetExists = circadianEventExists(CircadianEventType.Sunset);
  const duskExists = circadianEventExists(CircadianEventType.Dusk);
  const nightfallExists = circadianEventExists(CircadianEventType.Nightfall);

  const offsetDegrees = timeToDegrees(solarNoon);

  const daybreakDegrees =
    daybreakExists && calculateSunpathDegrees(daybreak, offsetDegrees);
  const dawnDegrees = dawnExists && calculateSunpathDegrees(dawn, offsetDegrees);
  const sunriseDegrees = sunriseExists && calculateSunpathDegrees(sunrise, offsetDegrees);
  const solarNoonDegrees = 0; // Solar noon is always 0 degrees
  const sunsetDegrees = sunsetExists && calculateSunpathDegrees(sunset, offsetDegrees);
  const duskDegrees = duskExists && calculateSunpathDegrees(dusk, offsetDegrees);
  const nightfallDegrees =
    nightfallExists && calculateSunpathDegrees(nightfall, offsetDegrees);
  const nadirDegrees = calculateSunpathDegrees(nadir, offsetDegrees, offsetDegrees);
  const currentDegrees = calculateSunpathDegrees(sunElevationTime, offsetDegrees);
  const uvaRiseDegrees = uvaRiseExists && calculateSunpathDegrees(uvaRise, offsetDegrees);
  const uvaSetDegrees = uvaSetExists && calculateSunpathDegrees(uvaSet, offsetDegrees);
  const uvbRiseDegrees = uvbRiseExists && calculateSunpathDegrees(uvbRise, offsetDegrees);
  const uvbSetDegrees = uvbSetExists && calculateSunpathDegrees(uvbSet, offsetDegrees);

  function generateDegreeMaps() {
    const degreeMaps = new Map();
    degreeMaps.set(CircadianEventType.Nadir, nadirDegrees);
    if (daybreakExists) degreeMaps.set(CircadianEventType.Daybreak, daybreakDegrees);
    if (dawnExists) degreeMaps.set(CircadianEventType.Dawn, dawnDegrees);
    if (sunriseExists) degreeMaps.set(CircadianEventType.Sunrise, sunriseDegrees);
    if (uvaRiseExists) degreeMaps.set(CircadianEventType.UvaRise, uvaRiseDegrees);
    if (uvbRiseExists) degreeMaps.set(CircadianEventType.UvbRise, uvbRiseDegrees);
    degreeMaps.set(CircadianEventType.SolarNoon, solarNoonDegrees);
    if (uvaSetExists) degreeMaps.set(CircadianEventType.UvaSet, uvaSetDegrees);
    if (uvbSetExists) degreeMaps.set(CircadianEventType.UvbSet, uvbSetDegrees);
    if (sunsetExists) degreeMaps.set(CircadianEventType.Sunset, sunsetDegrees);
    if (duskExists) degreeMaps.set(CircadianEventType.Dusk, duskDegrees);
    if (nightfallExists) degreeMaps.set(CircadianEventType.Nightfall, nightfallDegrees);

    return degreeMaps;
  }

  function generateRiseSetMaps() {
    const riseSetMaps = {};
    if (daybreakDegrees !== undefined && nightfallDegrees !== undefined) {
      riseSetMaps[SunTypes.Night] = { rise: daybreakDegrees, set: nightfallDegrees };
      riseSetMaps[SunTypes.BlueHour] = { rise: daybreakDegrees, set: nightfallDegrees };
    }
    if (dawnDegrees !== undefined && duskDegrees !== undefined) {
      riseSetMaps[SunTypes.Twilight] = { rise: dawnDegrees, set: duskDegrees };
    }
    if (sunriseDegrees !== undefined && sunsetDegrees !== undefined) {
      riseSetMaps[SunTypes.Red] = { rise: sunriseDegrees, set: sunsetDegrees };
      riseSetMaps[SunTypes.SolarNoon] = { rise: sunriseDegrees, set: sunsetDegrees };
    }
    if (uvaRiseDegrees !== undefined && uvaSetDegrees !== undefined) {
      riseSetMaps[SunTypes.UVA] = { rise: uvaRiseDegrees, set: uvaSetDegrees };
      riseSetMaps[SunTypes.SolarNoon] = { rise: uvaRiseDegrees, set: uvaSetDegrees };
    }
    if (uvbRiseDegrees !== undefined && uvbSetDegrees !== undefined) {
      riseSetMaps[SunTypes.UVB] = { rise: uvbRiseDegrees, set: uvbSetDegrees };
      riseSetMaps[SunTypes.SolarNoon] = { rise: uvbRiseDegrees, set: uvbSetDegrees };
    }

    return riseSetMaps;
  }

  function generateDashArrays() {
    const dashArrays = {};
    if (daybreakDegrees !== undefined && nightfallDegrees !== undefined) {
      dashArrays[SunTypes.BlueHour] = calculateDashArray(
        daybreakDegrees,
        nightfallDegrees,
      );
      dashArrays[SunTypes.Night] = calculateDashArray(nightfallDegrees, daybreakDegrees);
    }
    if (dawnDegrees !== undefined && duskDegrees !== undefined) {
      dashArrays[SunTypes.Twilight] = calculateDashArray(dawnDegrees, duskDegrees);
    }
    if (sunriseDegrees !== undefined && sunsetDegrees !== undefined) {
      dashArrays[SunTypes.Red] = calculateDashArray(sunriseDegrees, sunsetDegrees);
      dashArrays[SunTypes.SolarNoon] = calculateDashArray(sunriseDegrees, sunsetDegrees);
    }
    if (uvaRiseDegrees !== undefined && uvaSetDegrees !== undefined) {
      dashArrays[SunTypes.UVA] = calculateDashArray(uvaRiseDegrees, uvaSetDegrees);
      dashArrays[SunTypes.SolarNoon] = calculateDashArray(uvaRiseDegrees, uvaSetDegrees);
    }
    if (uvbRiseDegrees !== undefined && uvbSetDegrees !== undefined) {
      dashArrays[SunTypes.UVB] = calculateDashArray(uvbRiseDegrees, uvbSetDegrees);
      dashArrays[SunTypes.SolarNoon] = calculateDashArray(uvbRiseDegrees, uvbSetDegrees);
    }
    return dashArrays;
  }

  function generateCircadianEventCoords() {
    const calculateLabelCoordsIfExists = (degrees, offset, limit) =>
      degrees !== undefined
        ? calculateCoordinates(sunPathRadius, degrees, offset)
        : limit;

    let daybreakLabelCoords = calculateLabelCoordsIfExists(daybreakDegrees, LabelOffset, {
      x: MinDaybreakXCoord,
      y: MaxDayYCoord,
    });
    const dawnLabelCoords = calculateLabelCoordsIfExists(
      dawnDegrees,
      LabelOffset + 12,
      {},
    );
    const sunriseLabelCoords = calculateLabelCoordsIfExists(
      sunriseDegrees,
      LabelOffset + 5,
      { x: 5, y: 5 },
    );
    const uvaRiseLabelCoords = calculateLabelCoordsIfExists(
      uvaRiseDegrees,
      LabelOffset - 3,
      {
        x: 8,
        y: 8,
      },
    );
    let uvbRiseLabelCoords = calculateLabelCoordsIfExists(
      uvbRiseDegrees,
      LabelOffset - 3,
      {
        x: 10,
        y: 10,
      },
    );
    if (
      uvbRiseLabelCoords &&
      (uvbRiseLabelCoords.x > MavUVBRiseXCoord || uvbRiseLabelCoords.y < MaxUVBYCoord)
    ) {
      uvbRiseLabelCoords = { x: MavUVBRiseXCoord, y: MaxUVBYCoord };
    }

    const solarNoonLabelCoords = calculateLabelCoordsIfExists(
      solarNoonDegrees,
      LabelOffset - 8.5,
      { x: 6, y: 6 },
    );
    const uvaSetLabelCoords = calculateLabelCoordsIfExists(
      uvaSetDegrees,
      LabelOffset + 3,
      {
        x: 8,
        y: 8,
      },
    );
    const sunsetLabelCoords = calculateLabelCoordsIfExists(
      sunsetDegrees,
      LabelOffset + 5,
      { x: 5, y: 5 },
    );
    const duskLabelCoords = calculateLabelCoordsIfExists(
      duskDegrees,
      LabelOffset + 12,
      {},
    );
    let nightfallLabelCoords = calculateLabelCoordsIfExists(
      nightfallDegrees,
      LabelOffset,
      {
        x: MaxNightFallXCoord,
        y: MaxDayYCoord,
      },
    );
    const nadirLabelCoords = calculateLabelCoordsIfExists(
      nadirDegrees,
      LabelOffset + 10,
      {},
    );

    let uvbSetLabelCoords = calculateLabelCoordsIfExists(uvbSetDegrees, LabelOffset, {
      x: 10,
      y: 10,
    });
    if (
      uvbSetLabelCoords &&
      (uvbSetLabelCoords.x < MinUVBSetXCoord || uvbSetLabelCoords.y < MaxUVBYCoord)
    ) {
      uvbSetLabelCoords = { x: MinUVBSetXCoord, y: MaxUVBYCoord };
    }

    const calculateDateCoordsIfExists = (degrees, offsetX, offsetY) =>
      degrees !== undefined
        ? calculateDateCoords(degrees, radius, offsetX, offsetY)
        : null;

    const daybreakDateCoords = calculateDateCoordsIfExists(
      nightfallDegrees,
      RiseDateXOffset,
      DateYOffset,
    );
    const dawnDateCoords = calculateDateCoordsIfExists(
      duskDegrees,
      RiseDateXOffset,
      DateYOffset,
    );
    const sunriseDateCoords = calculateDateCoordsIfExists(
      sunsetDegrees,
      RiseDateXOffset,
      DateYOffset,
    );
    const uvaRiseDateCoords = calculateDateCoordsIfExists(
      uvaSetDegrees,
      RiseDateXOffset,
      DateYOffset,
    );

    let uvbRiseDateCoords = calculateDateCoordsIfExists(
      uvbSetDegrees,
      RiseDateXOffset,
      DateYOffset,
    );
    if (
      uvbRiseDateCoords &&
      (uvbRiseDateCoords.x > -95.7 || uvbRiseDateCoords.y < -156.13)
    ) {
      uvbRiseDateCoords = { x: -95.7, y: -156.13 };
    }

    const solarNoonDateCoords = calculateDateCoordsIfExists(
      solarNoonDegrees,
      SetDateXOffset / 2,
      18,
    );
    const uvaSetDateCoords = calculateDateCoordsIfExists(
      uvaRiseDegrees,
      SetDateXOffset,
      DateYOffset,
    );
    const sunsetDateCoords = calculateDateCoordsIfExists(
      sunriseDegrees,
      SetDateXOffset,
      DateYOffset,
    );
    const duskDateCoords = calculateDateCoordsIfExists(
      dawnDegrees,
      SetDateXOffset,
      DateYOffset,
    );
    const nightfallDateCoords = calculateDateCoordsIfExists(
      daybreakDegrees,
      SetDateXOffset,
      DateYOffset,
    );

    let uvbSetDateCoords = calculateDateCoordsIfExists(
      uvbRiseDegrees,
      SetDateXOffset,
      DateYOffset,
    );
    if (uvbSetDateCoords && (uvbSetDateCoords.x < 32.7 || uvbSetDateCoords.y < -156.13)) {
      uvbSetDateCoords = { x: 32.7, y: -156.13 };
    }

    const nadirDateCoords = calculateDateCoordsIfExists(
      nadirDegrees,
      SetDateXOffset / 2,
      -6,
    );

    const isDawnVisible = !daybreakExists;
    const isDuskVisible = !nightfallExists;

    return {
      sunElevation: sunCoords,
      ...(daybreakExists
        ? { daybreak: { label: daybreakLabelCoords, date: daybreakDateCoords } }
        : { daybreak: { invalid: true } }),
      ...(dawnExists
        ? {
            dawn: {
              label: dawnLabelCoords,
              date: dawnDateCoords,
              visible: isDawnVisible,
            },
          }
        : { dawn: { invalid: true } }),
      ...(sunriseExists
        ? { sunrise: { label: sunriseLabelCoords, date: sunriseDateCoords } }
        : { sunrise: { invalid: true } }),
      ...(uvaRiseExists
        ? { uvaRise: { label: uvaRiseLabelCoords, date: uvaRiseDateCoords } }
        : { uvaRise: { invalid: true } }),
      ...(uvbRiseExists
        ? { uvbRise: { label: uvbRiseLabelCoords, date: uvbRiseDateCoords } }
        : { uvbRise: { invalid: true } }),
      solarNoon: { label: solarNoonLabelCoords, date: solarNoonDateCoords },
      ...(uvbRiseExists
        ? { uvbSet: { label: uvbSetLabelCoords, date: uvbSetDateCoords } }
        : { uvbSet: { invalid: true } }),
      ...(uvaRiseExists
        ? { uvaSet: { label: uvaSetLabelCoords, date: uvaSetDateCoords } }
        : { uvaSet: { invalid: true } }),
      ...(sunsetExists
        ? { sunset: { label: sunsetLabelCoords, date: sunsetDateCoords } }
        : { sunset: { invalid: true } }),
      ...(duskExists
        ? {
            dusk: {
              label: duskLabelCoords,
              date: duskDateCoords,
              visible: isDuskVisible,
            },
          }
        : { dusk: { invalid: true } }),
      ...(nightfallExists
        ? { nightfall: { label: nightfallLabelCoords, date: nightfallDateCoords } }
        : { nightfall: { invalid: true } }),
      nadir: { label: nadirLabelCoords, date: nadirDateCoords },
    };
  }

  const degreeMaps = generateDegreeMaps();
  const riseSetMaps = generateRiseSetMaps();
  const dashArrays = generateDashArrays();
  const sunPathRadius = radius + SunOffset;
  const sunCoords = calculateCoordinates(sunPathRadius, currentDegrees);
  const circadianEventCoords = generateCircadianEventCoords();
  const activeRiseDegrees = riseSetMaps[sunType]?.rise;
  const activeSetDegrees = riseSetMaps[sunType]?.set;
  const { activeDegrees: activeClockMarks, eventDegrees: eventClockMarks } =
    getEventMarkers(currentDegrees, degreeMaps);
  const dashArray = dashArrays[sunType];
  const { defaultDashArray, activeDashArray } = dashArray || calculateDashArray();

  const currentProgress = calculateSunProgress(currentDegrees, degreeMaps);
  const {
    endDegreeEntry: { key: nextSunType },
  } = findClosestDegreeEntries(currentDegrees, degreeMaps);

  return {
    circadianEvents,
    circadianEventCoords,
    activeRiseDegrees,
    activeSetDegrees,
    activeClockMarks,
    eventClockMarks,
    uvaExists: uvaRiseExists,
    uvbExists: uvbRiseExists,
    defaultDashArray,
    activeDashArray,
    currentProgress,
    sunType,
    nextSunType,
  };
};
