import React, { useEffect, useRef, useState } from 'react';
import { formatShortDay, getFormattedTime } from '../utils/time';
import { useSolarClock } from '../providers/solar-clock-provider';
import { isSunIcon } from '../icons/sun_icon';
import useBrowserInfo from '../hooks/use-browser-info';
import { getLayoutType } from '../components/sun_path';
import CircadianEventSelect from './circadian-event-select';
import { CircadianEventType } from './circadian_event';
import { useInfoPanel } from './info-panel';
import { getSunTypeGradient } from '../domain/sun-type';
import { useAuth } from '../providers/auth-provider';
import useFeature, { FeatureFlags } from '../hooks/use-feature';

const TODAY = 'today';
const TOMORROW = 'tomorrow';

export default function FloatingTime({ onClick, children }) {
  return (
    <div className="floating-time" onClick={onClick}>
      {children}
    </div>
  );
}

function DaySelectList({
  isToday,
  darkTheme,
  sunType,
  handleSelectTimeChange,
  openDateSelector,
  onClickOutside, // Add this prop for handling clicks outside the component
}) {
  const gradient = getSunTypeGradient(sunType);
  const isFeatureActive = useFeature();
  const componentRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        onClickOutside?.();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);

  return (
    <ul
      ref={componentRef}
      value={isToday ? TODAY : TOMORROW}
      className={`select-time ${darkTheme ? 'select-time-dark' : 'select-time-light'}`}
      style={{
        background: gradient,
      }}
    >
      <li onClick={() => handleSelectTimeChange(TODAY)}>Today</li>
      <li onClick={() => handleSelectTimeChange(TOMORROW)}>Tomorrow</li>
      {isFeatureActive(FeatureFlags.IsDateSelectActive) && (
        <li onClick={openDateSelector}>Select Date</li>
      )}
    </ul>
  );
}

export const DigitalTime = ({
  onMouseLeave,
  label,
  time,
  darkTheme,
  layout,
  onSelectEvent,
  onSelectTime,
  isToday,
  sunType,
  openDateSelector,
}) => {
  const [isSelectEventVisible, setIsSelectEventVisible] = useState(false);
  const [isSelectTimeVisible, setIsSelectTimeVisible] = useState(false);
  const className = darkTheme ? 'dark' : 'light';
  const gradient = getSunTypeGradient(sunType);
  const { user } = useAuth();

  function handleClickEvent() {
    setIsSelectEventVisible(true);
    setIsSelectTimeVisible(false);
  }

  function handleSelectEvent(props) {
    onSelectEvent(props);
    setIsSelectEventVisible(false);
  }

  function handleClickTime() {
    setIsSelectTimeVisible(true);
    setIsSelectEventVisible(false);
  }

  function handleSelectTimeChange(selectedEvent) {
    setIsSelectTimeVisible(false);
    onSelectTime(selectedEvent);
  }

  function closeEventList() {
    setIsSelectEventVisible(false);
  }

  function closeDayList() {
    setIsSelectTimeVisible(false);
  }

  return (
    <span key={1} className={`header-clock ${className}`} onMouseOver={onMouseLeave}>
      <div style={{ position: 'relative' }}>
        <span onClick={handleClickEvent}>{label ? label : 'Current Time'}</span>
        {isSelectEventVisible && (
          <CircadianEventSelect
            onEventSelect={handleSelectEvent}
            darkTheme={darkTheme}
            sunType={sunType}
            isToday={isToday}
            onClickOutside={closeEventList}
          />
        )}
        &nbsp;
        <span onClick={handleClickTime}>{getFormattedTime(time)}</span>
        {isSelectTimeVisible && (
          <DaySelectList
            isToday={isToday}
            darkTheme={darkTheme}
            sunType={sunType}
            handleSelectTimeChange={handleSelectTimeChange}
            openDateSelector={openDateSelector}
            onClickOutside={closeDayList}
          />
        )}
      </div>
    </span>
  );
};

export const HeaderTime = ({ time, sunType, openDateSelector }) => {
  const {
    activeEvent,
    displayDate,
    isDateToday,
    isDateTomorrow,
    activateEvent,
    clearEvent,
    today,
    tomorrow,
  } = useSolarClock();
  const { hideInfoPanel } = useInfoPanel();

  const { isMobileLayout } = useBrowserInfo();
  const layoutType = getLayoutType(isMobileLayout);

  const displayTime = time ? time : displayDate;
  let label;

  if (isDateToday(displayDate)) {
    label = activeEvent;
  } else if (isDateTomorrow(displayDate)) {
    label = `${activeEvent} tomorrow at`;
  } else {
    label = `${activeEvent} ${formatShortDay(displayDate)} at`;
  }

  const handleSelectTime = (selectedTime) => {
    if (selectedTime === TOMORROW) {
      activateEvent(CircadianEventType.Sunrise, tomorrow);
    } else if (selectedTime === TODAY) {
      clearEvent(today);
    }
    hideInfoPanel();
  };

  return (
    <DigitalTime
      label={label}
      time={displayTime}
      darkTheme={!isSunIcon(sunType)}
      layout={'mobile'}
      onSelectEvent={activateEvent}
      onSelectTime={handleSelectTime}
      isToday={isDateToday(displayDate)}
      sunType={sunType}
      openDateSelector={openDateSelector}
    />
  );
};
