import React, { useMemo, useRef, useState } from 'react';
import SunIcon, { isMoonIcon, isSunIcon } from '../icons/sun_icon';
import { getLineCoords } from '../utils/math';
import MoonPhase from '../icons/moon-svg';
import { CircadianEventsList, CircadianEventType } from './circadian_event';

import '../css/SunPath.css';
import { useInfoPanel } from './info-panel';
import { getDateTime, getFormattedTime } from '../utils/time';
import { generateSunPath } from '../utils/sun-path';
import FloatingTime, { DigitalTime } from './floating-time';

const { DateTime } = require('luxon');

export const LayoutTypes = {
  Mobile: 'mobile',
  Desktop: 'desktop',
};

export function getLayoutType(isMobile) {
  return isMobile ? LayoutTypes.Mobile : LayoutTypes.Desktop;
}

export function isDesktopLayout(layout) {
  return layout === LayoutTypes.Desktop;
}

export function isMobileLayout(layout) {
  // default to mobile
  return layout !== LayoutTypes.Desktop;
}

export const SunTypes = {
  Red: 'red',
  UVA: 'uva',
  UVB: 'uvb',
  Night: 'night',
  Twilight: 'twilight',
  BlueHour: 'blue_hour',
  SolarNoon: 'noon',
};

export const MoonTypes = [SunTypes.Twilight, SunTypes.BlueHour, SunTypes.Night];

function calculateRotation(riseDegrees, setDegrees) {
  const isRotated = true;
  if (!isRotated) {
    return 0;
  }

  const activeLength = riseDegrees - setDegrees;
  const defaultLength = 360 - activeLength;

  // Rotate half the difference between 180 and the activeLength
  // so that it is centered. Plus of minus based on winter vs summer
  const diff = Math.abs(180 - activeLength) / 2;
  return defaultLength > activeLength ? diff : -diff;
}

const SunIconXOffset = 40;
const SunIconYOffset = 55;
const MoonIconOffset = 55;

function CircadianEventLabel({ x, y, text, className, handleMouseOver, handleMouseOut }) {
  const initialPosition = useRef({ x: 0, y: 0 });
  const textRef = useRef(null);

  const handleMouseEnter = (event) => {
    initialPosition.current = { x: event.clientX, y: event.clientY };
    handleMouseOver(event);
  };

  const handleMouseLeave = (e) => {
    handleMouseOut(e);
  };

  return (
    <text ref={textRef} x={x} y={y} className={className} onMouseOver={handleMouseEnter}>
      {text}
    </text>
  );
}

function generateEvents(
  circadianEventCoords,
  uvaExists,
  uvbExists,
  getEventByType,
  formatDate,
  includeDate,
) {
  const events = [
    {
      type: CircadianEventType.SolarNoon,
      coords: includeDate
        ? circadianEventCoords.solarNoon.date
        : circadianEventCoords.solarNoon.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.SolarNoon).date)
        : 'Solar Noon',
    },
    {
      type: CircadianEventType.Nadir,
      coords: includeDate
        ? circadianEventCoords.nadir.date
        : circadianEventCoords.nadir.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.Nadir).date)
        : 'Nadir',
    },
  ];

  if (!circadianEventCoords.daybreak.invalid) {
    events.push({
      type: CircadianEventType.Daybreak,
      coords: includeDate
        ? circadianEventCoords.daybreak.date
        : circadianEventCoords.daybreak.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.Daybreak).date)
        : 'Daybreak',
    });
    events.push({
      type: CircadianEventType.Nightfall,
      coords: includeDate
        ? circadianEventCoords.nightfall.date
        : circadianEventCoords.nightfall.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.Nightfall).date)
        : 'Nightfall',
    });
  }

  if (!circadianEventCoords.sunrise.invalid) {
    events.push({
      type: CircadianEventType.Sunrise,
      coords: includeDate
        ? circadianEventCoords.sunrise.date
        : circadianEventCoords.sunrise.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.Sunrise).date)
        : 'Sunrise',
    });
    events.push({
      type: CircadianEventType.Sunset,
      coords: includeDate
        ? circadianEventCoords.sunset.date
        : circadianEventCoords.sunset.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.Sunset).date)
        : 'Sunset',
    });
  }

  if (!circadianEventCoords.uvaRise.invalid) {
    events.push({
      type: CircadianEventType.UvaRise,
      coords: includeDate
        ? circadianEventCoords.uvaRise.date
        : circadianEventCoords.uvaRise.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.UvaRise).date)
        : 'UVA Rise',
    });
    events.push({
      type: CircadianEventType.UvaSet,
      coords: includeDate
        ? circadianEventCoords.uvaSet.date
        : circadianEventCoords.uvaSet.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.UvaSet).date)
        : 'UVA Set',
    });
  }

  if (!circadianEventCoords.uvbRise.invalid) {
    events.push({
      type: CircadianEventType.UvbRise,
      coords: includeDate
        ? circadianEventCoords.uvbRise.date
        : circadianEventCoords.uvbRise.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.UvbRise).date)
        : 'UVB Rise',
    });
    events.push({
      type: CircadianEventType.UvbSet,
      coords: includeDate
        ? circadianEventCoords.uvbSet.date
        : circadianEventCoords.uvbSet.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.UvbSet).date)
        : 'UVB Set',
    });
  }

  if (circadianEventCoords?.dawn?.visible) {
    events.push({
      type: CircadianEventType.Dawn,
      coords: includeDate
        ? circadianEventCoords.dawn.date
        : circadianEventCoords.dawn.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.Dawn).date)
        : 'Dawn',
    });
    events.push({
      type: CircadianEventType.Dusk,
      coords: includeDate
        ? circadianEventCoords.dusk.date
        : circadianEventCoords.dusk.label,
      text: includeDate
        ? formatDate(getEventByType(CircadianEventType.Dusk).date)
        : 'Dusk',
    });
  }

  return events;
}

function generateHandleTextMouseOver(
  _text,
  circadianEvents,
  handleOnHoverEvent,
  showTooltip,
) {
  // if (_text === 'UVB Rise') {
  //   showTooltip('UVB Rise');
  // }
  return (eventLabel) => (event) => {
    event.stopPropagation();
    const circadianEvent = circadianEvents.find((e) => e.label === eventLabel);
    handleOnHoverEvent(circadianEvent);
    showTooltip(eventLabel);
  };
}

function CircadianEventRenderer({
  events,
  circadianEvents,
  handleOnHoverEvent,
  handleOnLeaveEvent,
  textClassName,
  showTooltip,
  hideTooltip,
}) {
  const handleTextMouseOut = (e) => {
    hideTooltip();
    handleOnLeaveEvent(e);
  };

  return (
    <>
      {events.map((event) => (
        <CircadianEventLabel
          key={event.type}
          x={event.coords.x}
          y={event.coords.y}
          text={event.text}
          className={textClassName}
          handleMouseOver={generateHandleTextMouseOver(
            event.text,
            circadianEvents,
            handleOnHoverEvent,
            showTooltip,
          )(event.type)}
          handleMouseOut={handleTextMouseOut}
        />
      ))}
    </>
  );
}

function CircadianEventLabels({
  circadianEvents,
  circadianEventCoords,
  sunType,
  handleOnHoverEvent,
  handleOnLeaveEvent,
  uvaExists,
  uvbExists,
}) {
  const { showInfoPanel, hideInfoPanel } = useInfoPanel();
  const textClassName = isSunIcon(sunType) ? 'text-label-light' : 'text-label-dark';

  const events = generateEvents(
    circadianEventCoords,
    uvaExists,
    uvbExists,
    () => {},
    () => {},
    false,
  );

  return (
    <CircadianEventRenderer
      events={events}
      circadianEvents={circadianEvents}
      handleOnHoverEvent={handleOnHoverEvent}
      handleOnLeaveEvent={handleOnLeaveEvent}
      textClassName={textClassName}
      showTooltip={showInfoPanel}
      hideTooltip={hideInfoPanel}
    />
  );
}

function CircadianEventDates({
  circadianEvents,
  circadianEventCoords,
  handleOnHoverEvent,
  uvaExists,
  uvbExists,
}) {
  const { showTooltip, hideTooltip } = useInfoPanel();
  const textClassName = 'text-label-light';

  const getEventByType = (eventType) =>
    circadianEvents.find((e) => e.label === eventType);
  const events = generateEvents(
    circadianEventCoords,
    uvaExists,
    uvbExists,
    getEventByType,
    getFormattedTime,
    true,
  );

  return (
    <CircadianEventRenderer
      events={events}
      circadianEvents={circadianEvents}
      handleOnHoverEvent={handleOnHoverEvent}
      textClassName={textClassName}
      showTooltip={showTooltip}
      hideTooltip={hideTooltip}
    />
  );
}

function ClockMark({ spike, radius, index }) {
  const { x1, y1, x2, y2 } = getLineCoords(
    spike.angle,
    spike.length,
    radius - spike.length - 3,
    0,
    0,
    3,
  );
  return <line key={index} x1={x1} y1={y1} x2={x2} y2={y2} />;
}

function ClockMarks({
  activeColor,
  strokeWidth,
  activeClockMarks,
  radius,
  defaultColor,
  eventClockMarks,
}) {
  return (
    <>
      <g stroke={activeColor} strokeWidth={strokeWidth}>
        {activeClockMarks.map((spike, index) => {
          return <ClockMark key={index} spike={spike} radius={radius} index={index} />;
        })}
      </g>

      <g stroke={defaultColor} strokeWidth={strokeWidth}>
        {eventClockMarks.map((spike, index) => {
          return <ClockMark key={index} spike={spike} radius={radius} index={index} />;
        })}
      </g>
    </>
  );
}

const SunPath = ({
  radius = 200, // Outer circle radius
  strokeWidth = 5, // Stroke width for circles
  activeColor = '#ff5733', // Stroke color for the night circle
  defaultColor = 'grey', // Stroke color for the day circle
  fillColor = 'white', // Fill color for the circle
  sunTimes,
  sunElevationTime = new DateTime(),
  onMouseLeave,
  onHoverEvent,
  moonPhase,
  timezone,
  isAnimationRunning,
  layout,
  activeEvent,
  children,
}) => {
  const {
    circadianEventCoords,
    activeRiseDegrees,
    activeSetDegrees,
    activeClockMarks,
    eventClockMarks,
    uvaExists,
    uvbExists,
    defaultDashArray,
    activeDashArray,
    circadianEvents,
    currentProgress,
    sunType,
    nextSunType,
  } = useMemo(
    () => generateSunPath(sunTimes, sunElevationTime, radius),
    [sunTimes, sunElevationTime, radius],
  );
  const handleOnHoverEvent = (event) => {
    if (onHoverEvent) {
      onHoverEvent(event.label, getDateTime(event.date, timezone));
    }
  };
  const { showTooltip } = useInfoPanel();

  const rotation = calculateRotation(activeRiseDegrees, activeSetDegrees);

  // Todo - calculate with radius above? OR just remove radius
  const scale = 0.5;

  const handleOnMouseLeave = (e) => {
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  const scaledRadius = radius * scale;

  function getScaledCoord(xy, offset = 0) {
    return -scaledRadius + xy + offset;
  }

  const useDesktopLayout = isDesktopLayout(layout);

  const digitalTime = (
    <DigitalTime
      onMouseLeave={handleOnMouseLeave}
      label={activeEvent}
      time={sunElevationTime}
      darkTheme={!(isSunIcon(sunType) || useDesktopLayout)}
      layout={layout}
    />
  );

  let clockContent;
  if (useDesktopLayout) {
    clockContent = React.Children.toArray(children).concat(digitalTime);
  } else {
    const mobileEvents = (
      <CircadianEventsList
        sunTimes={sunTimes}
        timezone={timezone}
        onHoverEvent={onHoverEvent}
        sunElevationTime={sunElevationTime}
        activeEvent={activeEvent}
      />
    );
    clockContent = React.Children.toArray(children).concat(mobileEvents);
  }

  const handleClickCurrentTime = () => {
    if (activeEvent) {
      showTooltip(activeEvent);
    }
  };

  return (
    <div className="clock-container">
      <div className="svg-container" onMouseOut={handleOnMouseLeave}>
        <svg
          className="sun-path-svg"
          viewBox="-315 -315 630 630"
          xmlns="http://www.w3.org/2000/svg"
          overflow="visible"
        >
          <g transform={`rotate(${rotation})`}>
            {/* Outer circle with customizable stroke and fill */}
            <circle
              r={radius}
              stroke={defaultColor}
              strokeWidth={strokeWidth}
              pathLength={360}
              strokeDasharray={activeDashArray}
              fill={fillColor}
            />

            {/* Inner circle with customizable stroke */}
            <circle
              r={radius}
              stroke={activeColor}
              strokeWidth={strokeWidth}
              pathLength={360}
              strokeDasharray={defaultDashArray}
              fill="none"
            />
          </g>

          {!isAnimationRunning && !useDesktopLayout && (
            <ClockMarks
              activeColor={activeColor}
              strokeWidth={strokeWidth}
              activeClockMarks={activeClockMarks}
              radius={radius}
              defaultColor={defaultColor}
              eventClockMarks={eventClockMarks}
            />
          )}

          {isSunIcon(sunType) && (
            <g
              transform={`translate(${getScaledCoord(circadianEventCoords.sunElevation.x + SunIconXOffset)}, ${getScaledCoord(circadianEventCoords.sunElevation.y, -SunIconYOffset)}) scale(${scale})`}
            >
              <SunIcon
                sunType={sunType}
                nextSunType={nextSunType}
                progress={currentProgress}
                activeEvent={activeEvent}
                isMobile={!useDesktopLayout}
              />
            </g>
          )}
          {isMoonIcon(sunType) && (
            <g
              transform={`translate(${getScaledCoord(circadianEventCoords.sunElevation.x, -MoonIconOffset)}, ${getScaledCoord(circadianEventCoords.sunElevation.y, MoonIconOffset)}) scale(${scale})`}
            >
              <MoonPhase
                radius={75}
                phase={moonPhase}
                activeEvent={activeEvent}
                isMobile={!useDesktopLayout}
              />
            </g>
          )}

          {!isAnimationRunning && useDesktopLayout && (
            <>
              <CircadianEventLabels
                circadianEvents={circadianEvents}
                circadianEventCoords={circadianEventCoords}
                handleOnHoverEvent={handleOnHoverEvent}
                handleOnLeaveEvent={handleOnMouseLeave}
                uvaExists={uvaExists}
                uvbExists={uvbExists}
                sunType={sunType}
              />
              <CircadianEventDates
                circadianEvents={circadianEvents}
                circadianEventCoords={circadianEventCoords}
                handleOnHoverEvent={handleOnHoverEvent}
                handleOnLeaveEvent={handleOnMouseLeave}
                uvaExists={uvaExists}
                uvbExists={uvbExists}
                sunType={sunType}
              />
            </>
          )}
          {/* Align the text/content to be centered within the inner circle */}
          {!isAnimationRunning && clockContent && (
            <foreignObject x={-radius} y={-radius} width={radius * 2} height={radius * 2}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {clockContent}
              </div>
            </foreignObject>
          )}
        </svg>
      </div>
      {!useDesktopLayout && !isAnimationRunning && (
        <FloatingTime onClick={handleClickCurrentTime}>{digitalTime}</FloatingTime>
      )}
    </div>
  );
};

export default SunPath;
