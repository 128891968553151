import { useEffect, useState } from 'react';
import logger from '../utils/logger';

function getInitialCount(intervalDuration) {
  const now = new Date();
  const secondsUntilNextMinute = 60 - now.getSeconds();
  return Math.floor(secondsUntilNextMinute / (intervalDuration / 1000));
}

export function useInterval(intervalDuration) {
  const [count, setCount] = useState(getInitialCount(intervalDuration));

  useEffect(() => {
    let timeoutId;
    const updateTime = () => {
      setCount((prevCount) => prevCount + 1);
      const currentTime = new Date();
      const secondsUntilNextMinute = 60 - currentTime.getSeconds();
      timeoutId = setTimeout(updateTime, secondsUntilNextMinute * 1000);
    };

    updateTime();

    const visChange = () => {
      logger.log('Visibility Change in useInterval!');
      if (document.visibilityState === 'visible') {
        //        logger.log('Is Visible');
        updateTime();
      }
    };

    // Add the event listener
    window.addEventListener('visibilitychange', visChange);

    const wakeUp = () => {
      //      logger.log('Awake in useInterval!');
    };

    // Add the event listener
    window.addEventListener('wake', wakeUp);

    const pageShow = () => {
      //      logger.log('PageShow in useInterval!');
    };

    window.addEventListener('pageshow', pageShow);

    const focus = () => {
      //      logger.log('Focus in useInterval!');
    };

    window.addEventListener('focus', focus);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('wake', wakeUp);
      window.removeEventListener('visibilitychange', visChange);
      window.removeEventListener('pageshow', pageShow);
      window.removeEventListener('focus', focus);
    };
  }, [intervalDuration]);

  return count;
}
