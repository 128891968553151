import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Days } from '../hooks/use-current-time';
import { useInfoPanel } from '../components/info-panel';

// Create the context for the solar clock
const ModalContext = createContext();

export const useModal = () => {
  // Custom hook to access context values
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export const ModalTypes = {
  LocationSearch: 'LocationSearch',
  SignUp: 'SignUp',
  InviteOnly: 'InviteOnly',
  Debug: 'Debug',
  ExposureGuide: 'ExposureGuide',
  SkinType: 'SkinType',
  AdjustmentFactors: 'AdjustMentFactors',
  CompassTroubleShooting: 'CompassTroubleShooting',
  LocationTroubleShooting: 'LocationTroubleShooting',
  SunGazing: 'SunGazing',
};

export const ModalProvider = ({ children }) => {
  const { hideInfoPanel } = useInfoPanel();
  const [activeModal, setActiveModal] = useState();
  const isModalOpen = useCallback(
    (modalType) => {
      return activeModal === modalType;
    },
    [activeModal],
  );

  const openModal = (modalType) => {
    hideInfoPanel();
    setActiveModal(modalType);
  };

  const closeModal = () => {
    setActiveModal(undefined);
  };

  // Provide context to children components
  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        isModalOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
