import React, { useEffect } from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';
import { useSolarClock } from '../../providers/solar-clock-provider';
import { Days } from '../../hooks/use-current-time';
import { formatDayTimeAndZone } from '../../utils/time';
import { SunTypes } from '../../domain/sun-type';

export default function DefaultModal({
  darkTheme,
  sunType,
  autoCloseTime,
  onClose,
  isOpen,
  content,
  autoHeight,
}) {
  useEffect(() => {
    if (autoCloseTime) {
      const timer = setTimeout(() => {
        onClose();
      }, autoCloseTime);
      return () => clearTimeout(timer);
    }
  }, [autoCloseTime, onClose]);

  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      darkTheme={darkTheme}
      sunType={sunType}
      autoHeight={autoHeight}
    >
      {typeof content === 'string' ? <p>{content}</p> : content}
    </Modal>
  );
}
