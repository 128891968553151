import React, { useState, useEffect, useRef } from 'react';
import '../css/HamburgerMenu.css'; // Import the CSS file
import { usePage } from '../providers/page-provider';
import { getSunTypeGradient } from '../domain/sun-type';
import { useInfoPanel } from './info-panel';
import useBrowserInfo from '../hooks/use-browser-info';

function MenuItems({ menuItems, isActivePage, hideInfoPanel, goToPage }) {
  return (
    <ul>
      {menuItems
        .sort((a, b) => a.index - b.index)
        .map((item, index) => {
          const isActiveItem = isActivePage(item.link);

          const onClick = () => {
            if (isActivePage(item.link)) {
              return;
            }
            if (typeof item.link === 'function') {
              item.link();
            } else {
              hideInfoPanel();
              goToPage(item.link);
            }
          };

          return (
            <li key={item.key} className={`menu-item ${index === 0 ? 'top-item' : ''}`}>
              <div
                onClick={onClick}
                className={`item-name ${isActiveItem ? 'active-item' : ''}`}
              >
                {item.name}
              </div>
            </li>
          );
        })}
    </ul>
  );
}

function Hamburger({ isOpen, isAnimating, toggleMenu, barClass }) {
  return (
    <div className={`hamburger ${isAnimating ? 'open' : ''}`} onClick={toggleMenu}>
      <span className={barClass}></span>
      <span className={barClass}></span>
      <span className={barClass}></span>
    </div>
  );
}

const HamburgerMenu = ({ menuItems, darkTheme, sunType }) => {
  const { goToPage, isActivePage } = usePage();
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // State to handle animation
  const { hideInfoPanel } = useInfoPanel();
  const gradient = getSunTypeGradient(sunType);
  const barClass = darkTheme ? 'bar bar-dark' : 'bar';
  const menuRef = useRef(null);
  const { isMobileLayout } = useBrowserInfo();

  const toggleMenu = () => {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => {
        setIsAnimating(true);
      }, 0);
    } else {
      // For mobile layout, close the menu first, then animate the hamburger
      setIsOpen(false);
      if (isMobileLayout) {
        setTimeout(() => {
          setIsAnimating(false);
        }, 0); // Delay for animation duration, adjust as needed to match CSS
      } else {
        setIsAnimating(false);
      }
    }
  };

  // Handle clicks outside of the menu to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (isMobileLayout) {
          setIsOpen(false);
          setTimeout(() => {
            setIsAnimating(false);
          }, 300); // Delay to allow the close animation to play
        } else {
          setIsAnimating(false);
          setIsOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileLayout]);

  const headerColor = darkTheme ? 'dark-mode-text-color' : '';
  const headerClass = darkTheme ? 'header dark-mode-text-color' : 'header';
  const headerTopClass = `${headerColor} header-top-base header-top-space-between`;
  const titleClass = darkTheme ? 'title-dark' : 'title-light';

  if (isMobileLayout) {
    if (!isOpen) {
      return (
        <div ref={menuRef} className="hamburger-container">
          <Hamburger
            isOpen={isOpen}
            isAnimating={isAnimating}
            toggleMenu={toggleMenu}
            barClass={barClass}
          />
        </div>
      );
    }
    return (
      <div ref={menuRef} className="hamburger-container">
        <nav className={`menu${isOpen ? ' open' : ''}`} style={{ background: gradient }}>
          {isMobileLayout && (
            <div className={headerClass}>
              <div className={`header-top ${headerTopClass}`}>
                <div className={titleClass}>
                  <h1>Sunlight is Life</h1>
                </div>
                <Hamburger
                  isOpen={isOpen}
                  isAnimating={isAnimating}
                  toggleMenu={toggleMenu}
                  barClass={barClass}
                />
              </div>
            </div>
          )}

          <MenuItems
            menuItems={menuItems}
            isActivePage={isActivePage}
            hideInfoPanel={hideInfoPanel}
            goToPage={goToPage}
          />
        </nav>
      </div>
    );
  }

  return (
    <div ref={menuRef} className="hamburger-container">
      <div className={`hamburger ${isAnimating ? 'open' : ''}`} onClick={toggleMenu}>
        <span className={barClass}></span>
        <span className={barClass}></span>
        <span className={barClass}></span>
      </div>

      <nav className={`menu${isOpen ? ' open' : ''}`} style={{ background: gradient }}>
        <MenuItems
          menuItems={menuItems}
          isActivePage={isActivePage}
          hideInfoPanel={hideInfoPanel}
          goToPage={goToPage}
        />
      </nav>
    </div>
  );
};

export default HamburgerMenu;
