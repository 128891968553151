import React from 'react';

import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';

import '../../css/Compass.css';

const SunGazing = () => {
  return (
    <div className="sun-gazing-container">
      <h1>Sun Gazing</h1>

      <section className="history-section">
        <h2>Ancient Roots of Sun Gazing</h2>
        <p>
          Sun gazing is an ancient practice that has deep roots in many cultures. Ancient
          Egyptians believed that the sun was a deity, Ra, and considered sunlight
          essential for health and vitality. Similarly, the Incas, Aztecs, and other
          indigenous cultures revered the sun and engaged in rituals involving direct
          interaction with sunlight, seeing it as a source of spiritual power and
          sustenance.
        </p>
        <p>
          In India, sun gazing was part of a traditional practice known as "Surya
          Namaskar," or "Salutation to the Sun." Practitioners believed that the sun not
          only nourished the body but also the spirit, providing clarity and enhancing
          consciousness.
        </p>
      </section>

      <section className="modern-techniques">
        <h2>Modern Sun Gazing Techniques and Benefits</h2>
        <p>
          Modern sun gazing techniques are rooted in a mixture of traditional practice and
          a better understanding of the science of light. The general approach to sun
          gazing today involves looking at the sun during safe times—typically during the
          first hour after sunrise and the last hour before sunset, often called the
          "golden hours."
        </p>
        <p>
          By limiting exposure to these periods, practitioners reduce the risk of eye
          damage while still benefiting from the spectrum of light that can help regulate
          circadian rhythms and stimulate the pineal gland, potentially leading to
          increased melatonin and serotonin production. Scientific research suggests that
          safe exposure to sunlight can have numerous health benefits, including improving
          mood, regulating sleep cycles, and even boosting vitamin D levels.
        </p>
        <p>
          Advocates of modern sun gazing also emphasize the importance of starting with
          short durations—around 10 seconds at first—and gradually increasing the time as
          the eyes adjust. It is crucial to listen to one's body and avoid straining or
          discomfort.
        </p>
      </section>

      <section className="precautions">
        <h2>Precautions and Safety Tips</h2>
        <p>
          Sun gazing should always be approached with caution. Avoid looking directly at
          the sun outside of the early morning or late evening hours, as this can cause
          serious eye damage. Instead, it is recommended to gaze approximately 15 degrees
          off-center from the sun to reduce the risk of eye strain or damage.
        </p>
        <p>
          It's essential to practice mindfulness and stop immediately if there is any
          discomfort, seeing spots or blurring vision. The practice is not suitable for
          everyone, and individuals with eye conditions should consult a healthcare
          professional before attempting sun gazing. Always prioritize safety and
          understand your own limits.
        </p>
      </section>
    </div>
  );
};

export default function SunGazingModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  return (
    <Modal
      show={isModalOpen(ModalTypes.SunGazing)}
      darkTheme={darkTheme}
      sunType={sunType}
      onClose={closeModal}
    >
      <SunGazing />
    </Modal>
  );
}
