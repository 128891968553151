const CompassSvg = ({
  azimuth,
  sunRadians = 0,
  SunComponent,
  sunRadius,
  moonRadians = 0,
  MoonComponent,
  moonRadius,
  darkMode,
}) => {
  const radius = 100;
  const centerX = 150;
  const centerY = 150;

  const adjustedSunRadians = sunRadians; // - Math.PI;
  const adjustedMoonRadians = moonRadians; // - Math.PI;

  // Calculate icon position based on radians, where 0 is South and Math.PI / 2 is North
  const sunX = centerX + radius * Math.sin(adjustedSunRadians);
  const sunY = centerY - radius * Math.cos(adjustedSunRadians);
  console.log('Sun Radians', adjustedSunRadians);
  console.log('Sun X', radius * Math.cos(adjustedSunRadians));
  console.log('Sun Y', radius * Math.sin(adjustedSunRadians));
  console.log('Sun X plus center', sunX);
  console.log('Sun Y plus center', sunY);

  const moonX = centerX + radius * Math.sin(adjustedMoonRadians);
  const moonY = centerY - radius * Math.cos(adjustedMoonRadians);
  console.log('Moon Radians', adjustedMoonRadians);
  console.log('Moon X', radius * Math.cos(adjustedMoonRadians));
  console.log('Moon Y', radius * Math.sin(adjustedMoonRadians));
  console.log('Moon X plus center', moonX);
  console.log('Moon Y plus center', moonY);

  const lineColor = darkMode ? 'white' : 'black';

  return (
    <div style={{ position: 'relative', width: '300px', height: '300px' }}>
      {/* Draw the red arrow pointing to the top of the page */}
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -100%)',
          zIndex: 4,
        }}
      >
        <svg width="20" height={radius}>
          <defs>
            <marker
              id="arrowhead"
              markerWidth="6"
              markerHeight="6"
              refX="3"
              refY="3"
              orient="auto"
            >
              <polygon points="0 0, 6 3, 0 6" fill="red" />
            </marker>
          </defs>
          <line
            x1="10"
            y1={radius}
            x2="10"
            y2="10"
            stroke="red"
            strokeWidth="4"
            markerEnd="url(#arrowhead)"
          />
        </svg>
      </div>
      {/* Sun Icon position */}
      {SunComponent && (
        <div
          style={{
            position: 'absolute',
            left: sunX - sunRadius / 2,
            top: sunY - sunRadius / 2,
            width: sunRadius,
            height: sunRadius,
            zIndex: 2,
          }}
        >
          <SunComponent />
        </div>
      )}
      {/* Moon Icon position */}
      {MoonComponent && (
        <div
          style={{
            position: 'absolute',
            left: moonX - moonRadius / 2,
            top: moonY - moonRadius / 2,
            width: moonRadius,
            height: moonRadius,
            zIndex: 1,
          }}
        >
          <MoonComponent />
        </div>
      )}
      <svg
        width="300"
        height="300"
        viewBox="0 0 300 300"
        style={{ transform: `rotate(${azimuth}deg)`, position: 'relative', zIndex: 3 }}
      >
        {/* Draw the compass rose */}
        <circle
          cx={centerX}
          cy={centerY}
          r={radius}
          stroke={lineColor}
          strokeWidth="2"
          fill="none"
        />
        {/* Draw the compass rose arms */}
        <line
          x1={centerX}
          y1={centerY - radius}
          x2={centerX}
          y2={centerY + radius}
          stroke={lineColor}
          strokeWidth="1"
        />
        <line
          x1={centerX - radius}
          y1={centerY}
          x2={centerX + radius}
          y2={centerY}
          stroke={lineColor}
          strokeWidth="1"
        />
        <line
          x1={centerX - radius * Math.SQRT1_2}
          y1={centerY - radius * Math.SQRT1_2}
          x2={centerX + radius * Math.SQRT1_2}
          y2={centerY + radius * Math.SQRT1_2}
          stroke={lineColor}
          strokeWidth="1"
        />
        <line
          x1={centerX - radius * Math.SQRT1_2}
          y1={centerY + radius * Math.SQRT1_2}
          x2={centerX + radius * Math.SQRT1_2}
          y2={centerY - radius * Math.SQRT1_2}
          stroke={lineColor}
          strokeWidth="1"
        />
        {/* Markers for N, S, E, W */}
        <text
          x={centerX}
          y={centerY - radius - 15}
          textAnchor="middle"
          fontSize="20"
          fontWeight="bold"
          fill={lineColor}
        >
          N
        </text>
        <text
          x={centerX}
          y={centerY + radius + 20}
          textAnchor="middle"
          fontSize="16"
          fill={lineColor}
        >
          S
        </text>
        <text
          x={centerX + radius + 10}
          y={centerY}
          textAnchor="middle"
          fontSize="16"
          fill={lineColor}
        >
          E
        </text>
        <text
          x={centerX - radius - 20}
          y={centerY}
          textAnchor="middle"
          fontSize="16"
          fill={lineColor}
        >
          W
        </text>
        {/* Markers for NE, NW, SE, SW */}
        <text
          x={centerX + radius * Math.SQRT1_2 + 10}
          y={centerY - radius * Math.SQRT1_2 - 10}
          textAnchor="middle"
          fontSize="16"
          fill={lineColor}
        >
          NE
        </text>
        <text
          x={centerX - radius * Math.SQRT1_2 - 10}
          y={centerY - radius * Math.SQRT1_2 - 10}
          textAnchor="middle"
          fontSize="16"
          fill={lineColor}
        >
          NW
        </text>
        <text
          x={centerX + radius * Math.SQRT1_2 + 10}
          y={centerY + radius * Math.SQRT1_2 + 20}
          textAnchor="middle"
          fontSize="16"
          fill={lineColor}
        >
          SE
        </text>
        <text
          x={centerX - radius * Math.SQRT1_2 - 10}
          y={centerY + radius * Math.SQRT1_2 + 20}
          textAnchor="middle"
          fontSize="16"
          fill={lineColor}
        >
          SW
        </text>
      </svg>
    </div>
  );
};

export default CompassSvg;
