import { useMemo, useEffect, useState } from 'react';
import {
  getWindowDimensions,
  isTouchScreen as checkTouchScreen,
  isIOS as checkIOS,
  isAndroid as checkAndroid,
  isMobile as checkMobile,
  isDesktop as checkDesktop,
  isStandalone as checkStandalone,
} from '../utils/browser-utils';

export default function useBrowserInfo() {
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);
  const [windowHeight, setWindowHeight] = useState(getWindowDimensions().height);

  useEffect(() => {
    const handleResize = () => {
      const { width, height } = getWindowDimensions();
      setWindowWidth(width);
      setWindowHeight(height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobileLayout = useMemo(() => windowWidth <= 768, [windowWidth]);
  const isTouchScreen = useMemo(() => checkTouchScreen(), []);
  const isIOS = useMemo(() => checkIOS(), []);
  const isAndroid = useMemo(() => checkAndroid(), []);
  const isMobile = useMemo(() => checkMobile(), []);
  const isDesktop = useMemo(() => checkDesktop(), []);
  const isStandalone = useMemo(() => checkStandalone(), []);

  return {
    windowWidth,
    windowHeight,
    isMobileLayout,
    isTouchScreen,
    isIOS,
    isAndroid,
    isMobile,
    isDesktop,
    isStandalone,
  };
}
