import { formatDayTimeAndZone, getFormattedTime, isToday } from './time';
import { DateTime } from 'luxon';

export const SunTimeLabels = {
  Daybreak: 'daybreak',
  Dawn: 'dawn',
  Sunrise: 'sunrise',
  UvaRise: 'uvaRise',
  UvbRise: 'uvbRise',
  SolarNoon: 'solarNoon',
  UvbSet: 'uvbSet',
  UvaSet: 'uvaSet',
  Sunset: 'sunset',
  Dusk: 'dusk',
  Nightfall: 'nightfall',
  Nadir: 'nadir',
};

// Enum/Hash for UV Risk Levels
export const UVRiskLevels = {
  NONE: 0,
  LOW: 2,
  MODERATE: 3,
  HIGH: 6,
  VERY_HIGH: 8,
  EXTREME: 11,
};

export const getUvRiskValue = (uvIndex) => {
  if (uvIndex <= 2) {
    return UVRiskLevels.LOW;
  } else if (uvIndex >= 3 && uvIndex <= 5) {
    return UVRiskLevels.MODERATE;
  } else if (uvIndex >= 6 && uvIndex < 8) {
    return UVRiskLevels.HIGH;
  } else if (uvIndex >= 8 && uvIndex <= 10) {
    return UVRiskLevels.HIGH;
  } else if (uvIndex >= 11) {
    return UVRiskLevels.EXTREME;
  }
  return UVRiskLevels.NONE;
};

export const calculateUVStatus = (
  times,
  earlierEntry,
  laterEntry,
  currentTime,
  uvIndex,
  maxUv,
  maxUvTime,
  timezone,
  day,
) => {
  const [earlierLabel, earlierTime] = earlierEntry;

  // Determine the current and maximum UV risks
  const uvRisk = getUvRiskValue(uvIndex);
  const maxUvRisk = getUvRiskValue(maxUv);
  const uvbExists = times[SunTimeLabels.UvbRise];

  // Pre-computed values
  const uvaRiseTime = getFormattedTime(times[SunTimeLabels.UvaRise]);
  const uvbRiseTime = uvbExists ? getFormattedTime(times[SunTimeLabels.UvbRise]) : '';
  const maxUvTimeFormatted = getFormattedTime(maxUvTime);
  const uvbSetTime = getFormattedTime(times[SunTimeLabels.UvbSet]);
  const uvaSetTime = getFormattedTime(times[SunTimeLabels.UvaSet]);
  const sunsetTime = getFormattedTime(times[SunTimeLabels.Sunset]);

  // Message templates
  const minimalRiskMessage = (tense) => `The risk ${tense} minimal ${day.toLowerCase()}`;
  const lowRiskMessage = (tense) => `The risk ${tense} low ${day.toLowerCase()}`;
  const moderateRiskMessage = (tense) =>
    `There ${tense} potential for risk. Be mindful of sun exposure`;
  const highRiskMessage = (tense) =>
    `Risk ${tense} high. Closely monitor your sun exposure and take shade breaks as needed`;
  const veryHighRiskMessage = (tense) =>
    `Risk ${tense} very high. Closely monitor your sun exposure and take frequent shade breaks`;
  const extremeRiskMessage = (tense) =>
    `Risk ${tense} extremely high. You may need to limit direct sun exposure`;

  // Decide whether to use "is" or "will be"
  const getTense = (isCurrent) => (isCurrent ? 'is' : 'will be');
  const today = isToday(currentTime, timezone);
  const risingTense = today ? `is` : 'is expected to be';

  // UVA-specific messages
  const uvaWillAppearMessage = `UVA will appear around ${uvaRiseTime}`;
  const uvbAppearMessage = `UVB will appear around ${uvbRiseTime}`;

  // Custom content for each sun event based on UV risk

  // Sunrise and Below (Nadir, Daybreak, Dawn, Sunrise)
  if (
    [
      SunTimeLabels.Nadir,
      SunTimeLabels.Daybreak,
      SunTimeLabels.Dawn,
      SunTimeLabels.Sunrise,
    ].includes(earlierLabel)
  ) {
    const tense = getTense(false); // Forecasting risk
    if (maxUv < UVRiskLevels.LOW) {
      return `${uvaWillAppearMessage}, but ${minimalRiskMessage(tense).toLowerCase()}.`;
    } else if (maxUv < UVRiskLevels.MODERATE) {
      return `${uvaWillAppearMessage}, but ${lowRiskMessage(tense)}.`;
    } else if (maxUv < UVRiskLevels.HIGH) {
      return `${uvaWillAppearMessage}. The forecasted max UV is ${maxUv}. ${moderateRiskMessage(tense)}.`;
    } else if (maxUv < UVRiskLevels.VERY_HIGH) {
      return `${uvaWillAppearMessage}. The forecasted max UV is ${maxUv}. ${highRiskMessage(tense)}.`;
    } else if (maxUv < UVRiskLevels.EXTREME) {
      return `${uvaWillAppearMessage}. The forecasted max UV is ${maxUv}. ${veryHighRiskMessage(tense)}.`;
    } else {
      return `${uvaWillAppearMessage}. The forecasted max UV is ${maxUv}. ${extremeRiskMessage(tense)}.`;
    }
  }

  if (!uvbExists) {
    if (earlierLabel === SunTimeLabels.UvaRise) {
      return `UV ${risingTense} rising, but without UVB ${minimalRiskMessage('will be').toLowerCase()}.`;
    }

    if (earlierLabel === SunTimeLabels.SolarNoon) {
      return `UV ${risingTense} present and near its peak of ${maxUv} and will likely decline soon. ${minimalRiskMessage('is')}.`;
    }
  } else {
    if (earlierLabel === SunTimeLabels.UvaRise) {
      const tense = getTense(false); // Forecasting risk

      function generateUvMessage(riskMessage) {
        return `UV ${risingTense} rising. ${uvbAppearMessage} with a forecasted peak of ${maxUv}. ${riskMessage(tense)}.`;
      }

      if (maxUv < UVRiskLevels.LOW) {
        return generateUvMessage(minimalRiskMessage);
      } else if (maxUv < UVRiskLevels.MODERATE) {
        return generateUvMessage(lowRiskMessage);
      } else if (maxUv < UVRiskLevels.HIGH) {
        return generateUvMessage(moderateRiskMessage);
      } else if (maxUv < UVRiskLevels.VERY_HIGH) {
        return generateUvMessage(highRiskMessage);
      } else if (maxUv < UVRiskLevels.EXTREME) {
        return generateUvMessage(veryHighRiskMessage);
      } else {
        return generateUvMessage(extremeRiskMessage);
      }
    }

    if (earlierLabel === SunTimeLabels.UvbRise) {
      const tense = getTense(true); // Current risk

      function generateUvbMessage(riskMessage) {
        return `UVB ${risingTense} rising with a peak forecast of ${maxUv}. ${riskMessage(tense)}.`;
      }

      if (maxUv < UVRiskLevels.LOW) {
        return generateUvbMessage(minimalRiskMessage);
      } else if (maxUv < UVRiskLevels.MODERATE) {
        return generateUvbMessage(lowRiskMessage);
      } else if (maxUv < UVRiskLevels.HIGH) {
        return generateUvbMessage(moderateRiskMessage);
      } else if (maxUv < UVRiskLevels.VERY_HIGH) {
        return generateUvbMessage(highRiskMessage);
      } else if (maxUv < UVRiskLevels.EXTREME) {
        return generateUvbMessage(veryHighRiskMessage);
      } else {
        return generateUvbMessage(extremeRiskMessage);
      }
    }

    if (earlierLabel === SunTimeLabels.SolarNoon) {
      const tense = getTense(true); // Current risk
      const declineStatus = today
        ? 'will likely decline soon'
        : 'is expected to decline soon after';

      function generateSolarNoonMessage(prefix, riskMessage, additionalInfo = '') {
        return `${prefix} ${risingTense} near its peak of ${maxUv} and ${declineStatus}. ${riskMessage(tense)}${additionalInfo}`;
      }

      if (maxUv < UVRiskLevels.LOW) {
        return generateSolarNoonMessage('UV', minimalRiskMessage);
      } else if (maxUv < UVRiskLevels.MODERATE) {
        return generateSolarNoonMessage('UVB', lowRiskMessage);
      } else if (maxUv < UVRiskLevels.HIGH) {
        return generateSolarNoonMessage(
          'UVB',
          moderateRiskMessage,
          ` until closer to ${uvbSetTime}.`,
        );
      } else if (maxUv < UVRiskLevels.VERY_HIGH) {
        return generateSolarNoonMessage(
          'UVB',
          highRiskMessage,
          ` until closer to ${uvbSetTime}.`,
        );
      } else if (maxUv < UVRiskLevels.EXTREME) {
        return generateSolarNoonMessage(
          'UVB',
          veryHighRiskMessage,
          ` until closer to ${uvbSetTime}.`,
        );
      } else {
        return generateSolarNoonMessage(
          'UVB',
          extremeRiskMessage,
          ` until closer to ${uvbSetTime}.`,
        );
      }
    }

    if (earlierLabel === SunTimeLabels.UvbSet) {
      return `UV ${risingTense} waning and will disappear by ${uvaSetTime}.`;
    }
  }

  if (earlierLabel === SunTimeLabels.UvaSet) {
    const tense = today ? 'has' : 'will have';
    return `UV ${tense} disappeared. The sun will be up until ${sunsetTime}.`;
  }

  if (
    [SunTimeLabels.Sunset, SunTimeLabels.Dusk, SunTimeLabels.Nightfall].includes(
      earlierLabel,
    )
  ) {
    const tense = today ? 'is' : 'will be';
    return `The sun ${tense} down for the day. ${today ? 'Check back tomorrow.' : ''}`;
  }

  return '';
};

// Enum for Fitzpatrick Skin Types
export const SkinType = {
  I: 'I',
  II: 'II',
  III: 'III',
  IV: 'IV',
  V: 'V',
  VI: 'VI',
};

export const TanLevel = {
  NONE: 1.0, // No tan
  LIGHT: 1.3, // Light tan
  MODERATE: 1.5, // Moderate tan
  DEEP: 2.0, // Deep tan
};

export const TanRecency = {
  WITHIN_WEEK: 1.0, // Tan obtained within the last week (full strength)
  WITHIN_MONTH: 0.9, // Tan obtained within the last month (slightly faded)
  MORE_THAN_MONTH: 0.75, // Tan obtained more than a month ago (significantly faded)
};

export const TanRetention = {
  TANS_EASILY: 1.0, // Tan retains well
  FADES_QUICKLY: 0.85, // Tan fades quickly
};

// Function to calculate the adjustment factor based on the person's responses
function calculateTanningFactor(
  levelFactor = TanLevel.NONE,
  recencyFactor = TanRecency.WITHIN_WEEK,
  retentionFactor = TanRetention.TANS_EASILY,
) {
  // Combine the factors to determine the overall adjustment factor
  return levelFactor * recencyFactor * retentionFactor;
}

// Skin Type I (very fair, always burns): Very low MED (e.g., 150–200 mJ/cm²).
// Skin Type II (fair, burns easily): Slightly higher MED (e.g., 200–300 mJ/cm²).
// Skin Type III (average, sometimes burns): Moderate MED (e.g., 300–450 mJ/cm²).
// Skin Type IV (olive skin, rarely burns): Higher MED (e.g., 450–600 mJ/cm²).
// Skin Type V (brown skin, very rarely burns): Very high MED (e.g., 600–800 mJ/cm²).
// Skin Type VI (dark brown or black, never burns): Very high MED (e.g., 800–1200 mJ/cm²).

// Function to get MED based on skin type
const LOW_MED_VALUES = {
  [SkinType.I]: 150, // Type I
  [SkinType.II]: 200, // Type II
  [SkinType.III]: 300, // Type III
  [SkinType.IV]: 450, // Type IV
  [SkinType.V]: 600, // Type V
  [SkinType.VI]: 800, // Type VI
};

const STANDARD_MED_VALUES = {
  [SkinType.I]: 175, // Type I
  [SkinType.II]: 250, // Type II
  [SkinType.III]: 375, // Type III
  [SkinType.IV]: 525, // Type IV
  [SkinType.V]: 700, // Type V
  [SkinType.VI]: 1000, // Type VI
};

const HIGH_MED_VALUES = {
  [SkinType.I]: 200, // Type I
  [SkinType.II]: 300, // Type II
  [SkinType.III]: 450, // Type III
  [SkinType.IV]: 600, // Type IV
  [SkinType.V]: 800, // Type V
  [SkinType.VI]: 1200, // Type VI
};

export const CautionLevel = {
  Low: 1,
  Standard: 2,
  High: 3,
};

function getMEDForSkinType(skinType = SkinType.I, cautionLevel = CautionLevel.Standard) {
  if (cautionLevel === CautionLevel.Low) {
    return LOW_MED_VALUES[skinType] || LOW_MED_VALUES[SkinType.I];
  } else if (cautionLevel === CautionLevel.High) {
    return HIGH_MED_VALUES[skinType] || HIGH_MED_VALUES[SkinType.I];
  } else {
    return STANDARD_MED_VALUES[skinType] || STANDARD_MED_VALUES[SkinType.I];
  }
}

// Function to calculate altitude adjustment factor
function getAltitudeFactor(altitude) {
  // 10% increase in UV for every 1,000 meters
  return 1 + (altitude / 1000) * 0.1;
}

// Function to calculate latitude adjustment factor
function getLatitudeFactor(latitude) {
  if (Math.abs(latitude) <= 30) {
    return 1; // Low latitude
  } else if (Math.abs(latitude) <= 60) {
    return 0.8; // Mid latitude
  } else {
    return 0.6; // High latitude
  }
}

// Function to determine season factor based on date (Luxon DateTime object) and latitude
function getSeasonFactor(latitude, date) {
  const month = date.month; // Luxon provides month directly (1-12)

  if (latitude >= 0) {
    // Northern hemisphere
    if (month >= 6 && month <= 8) {
      return 1; // Summer
    } else if ((month >= 3 && month <= 5) || (month >= 9 && month <= 11)) {
      return 0.8; // Spring/Fall
    } else {
      return 0.5; // Winter
    }
  } else {
    // Southern hemisphere
    if (month === 12 || month === 1 || month === 2) {
      return 1; // Summer in Southern hemisphere
    } else if ((month >= 3 && month <= 5) || (month >= 9 && month <= 11)) {
      return 0.8; // Spring/Fall
    } else {
      return 0.5; // Winter
    }
  }
}

// Function to calculate total adjustment factor
function calculateGeoAdjustmentFactor(altitude, latitude, date) {
  const altitudeFactor = getAltitudeFactor(altitude);
  const latitudeFactor = getLatitudeFactor(latitude);
  const seasonFactor = getSeasonFactor(latitude, date);
  return altitudeFactor * latitudeFactor * seasonFactor;
}

function getAdjustedUVIntensity(
  uvIndex,
  altitude,
  latitude,
  date,
  tanLevel,
  tanRecency,
  tanRetention,
) {
  const geographicFactor = calculateGeoAdjustmentFactor(altitude, latitude, date);
  const tanningFactor = calculateTanningFactor(tanLevel, tanRecency, tanRetention);

  return uvIndex * geographicFactor * tanningFactor;
}
// Main function to calculate safe sun exposure time

export function calculateSafeSunExposureTime(
  uvIndex,
  altitude,
  latitude,
  date,
  skinType,
  cautionLevel,
  tanLevel,
  tanRecency,
  tanRetention,
) {
  const MED = getMEDForSkinType(skinType, cautionLevel);
  const safeExposureTime =
    MED /
    getAdjustedUVIntensity(
      uvIndex,
      altitude,
      latitude,
      date,
      tanLevel,
      tanRecency,
      tanRetention,
    );
  return Math.round(safeExposureTime);
}

// Function to calculate safe sun exposure time considering UV forecast data
export function calculateSafeForecastedSunExposureTime(
  currentUVIndex,
  currentTime, // current time as a Luxon DateTime object
  forecastedUVData, // UV forecast data set
  altitude,
  latitude,
  date,
  skinType,
  cautionLevel,
  tanLevel,
  tanRecency,
  tanRetention,
) {
  let totalSafeExposureTime = 0;
  let remainingExposureTime = calculateSafeSunExposureTime(
    currentUVIndex,
    altitude,
    latitude,
    date,
    skinType,
    cautionLevel,
    tanLevel,
    tanRecency,
    tanRetention,
  );

  // Iterate over the forecasted UV data to calculate cumulative exposure
  for (let i = 0; i < forecastedUVData.length; i++) {
    const { uvIndex, uvTime } = forecastedUVData[i];
    const forecastTime = DateTime.fromISO(uvTime).setZone(currentTime.zoneName);

    let timeDifferenceMinutes = 0;

    // For the first forecasted UV value, calculate the time difference from the current time
    if (currentTime > forecastTime) {
      continue; // Skip if the forecasted time is in the past
    } else {
      // For subsequent forecasted UV values, calculate the time difference from the previous forecast
      const previousForecastTime =
        timeDifferenceMinutes === 0
          ? currentTime
          : DateTime.fromISO(forecastedUVData[i - 1].uvTime).setZone(
              currentTime.zoneName,
            );
      timeDifferenceMinutes = forecastTime.diff(previousForecastTime, 'minutes').minutes;
    }

    // Calculate the safe exposure time for this UV level
    const segmentSafeExposureTime =
      remainingExposureTime /
      getAdjustedUVIntensity(
        uvIndex,
        altitude,
        latitude,
        date,
        tanLevel,
        tanRecency,
        tanRetention,
      );

    // Calculate how much time of the segment we can safely be in the sun
    const maxSafeTimeForSegment = Math.min(remainingExposureTime, timeDifferenceMinutes);
    const exposureFraction = maxSafeTimeForSegment / segmentSafeExposureTime;

    // Decrease the remaining exposure time based on time spent
    remainingExposureTime -= exposureFraction * timeDifferenceMinutes;
    totalSafeExposureTime += maxSafeTimeForSegment;

    // console.log(
    //   `At UV ${uvIndex} (${forecastTime.toISO()}): Total safe exposure time so far is ${totalSafeExposureTime} minutes`,
    // );

    // If no remaining safe time, break early
    if (remainingExposureTime <= 0) {
      break; // Exposure limit has been reached
    }
  }

  return Math.round(totalSafeExposureTime);
}
