import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import {
  loadSkinTypeDataFromCache,
  saveSkinTypeDataToCache,
  loadAdjustmentFactorsFromCache,
  saveAdjustmentFactorsToCache,
} from '../utils/local-storage';
import { useSolarClock } from '../providers/solar-clock-provider';
import { fetchUVIndex, fetchUVForecast, UvUpdateInterval } from '../clients/uv-utils';
import { useInterval } from '../hooks/use-interval';
import { formatDayTimeAndZone } from '../utils/time';
import { DateTime } from 'luxon';
import { CautionLevel, TanLevel, TanRecency, TanRetention } from '../utils/uv-content';

const UvContext = createContext();

export const UvProvider = ({ children }) => {
  const [skinType, setSkinType] = useState(undefined);
  const [adjustmentFactors, setAdjustmentFactors] = useState({
    cautionLevel: CautionLevel.Standard,
    tanLevel: TanLevel.NONE,
    tanRecency: TanRecency.WITHIN_WEEK,
    tanRetention: TanRetention.TANS_EASILY,
  });
  const [uvIndex, setUvIndex] = useState(undefined);
  const [maxUv, setMaxUv] = useState(undefined);
  const [maxUvTime, setMaxUvTime] = useState(undefined);
  const [uvForecast, setUvForecast] = useState([]);
  const [isUvLoaded, setIsUvLoaded] = useState(false);

  const { timezone, displayDate, isDateToday, isClockDataLoaded } = useSolarClock();

  const count = useInterval(UvUpdateInterval);

  const { coordinates } = useSolarClock();

  useEffect(() => {
    void loadSkinTypeDataFromCache().then((st) => {
      if (st) {
        setSkinType(st);
      }
    });
    void loadAdjustmentFactorsFromCache().then((af) => {
      if (af) {
        setAdjustmentFactors(af);
      }
    });
  }, []);

  useEffect(() => {
    if (!isClockDataLoaded) {
      return;
    }

    if (isDateToday(displayDate)) {
      // Fetch UV Index and UV Forecast for today using Promise.all
      Promise.all([
        fetchUVIndex(coordinates.latitude, coordinates.longitude, displayDate).then(
          (data) => {
            setUvIndex(Math.round(data.uvIndex));
            setMaxUv(Math.round(data.maxUv));
            setMaxUvTime(DateTime.fromISO(data.maxUvTime).setZone(timezone));
          },
        ),
        fetchUVForecast(
          coordinates.latitude,
          coordinates.longitude,
          displayDate,
          timezone,
        ).then((data) => {
          setUvForecast(data);
        }),
      ]).then(() => setIsUvLoaded(true));
    } else {
      fetchUVForecast(coordinates.latitude, coordinates.longitude, displayDate, timezone)
        .then((data) => {
          setUvForecast(data);

          // Find the maximum UV and corresponding time from the forecast
          let maxUv = -Infinity;
          let maxUvTime = null;
          let closestUv = null;
          let closestTimeDiff = Infinity;

          data.forEach((item) => {
            const itemTime = DateTime.fromISO(item.uvTime).setZone(timezone);
            const itemUv = item.uvIndex;

            // Update max UV and time
            if (itemUv > maxUv) {
              maxUv = itemUv;
              maxUvTime = itemTime;
            }

            // Find the UV closest to the displayDate time
            const timeDiff = Math.abs(itemTime.diff(displayDate, 'minutes').minutes);
            if (timeDiff < closestTimeDiff) {
              closestTimeDiff = timeDiff;
              closestUv = itemUv;
            }
          });

          setMaxUv(Math.round(maxUv));
          setMaxUvTime(maxUvTime);
          setUvIndex(Math.round(closestUv));
        })
        .then(() => setIsUvLoaded(true));
    }
  }, [
    coordinates.latitude,
    coordinates.longitude,
    displayDate.year,
    displayDate.month,
    displayDate.day,
    isClockDataLoaded,
  ]);

  const saveSkinType = (newSkinType) => {
    saveSkinTypeDataToCache(newSkinType);
    setSkinType(newSkinType);
  };

  const saveAdjustmentFactors = ({
    cautionLevel = CautionLevel.Standard,
    tanLevel = TanLevel.NONE,
    tanRecency = TanRecency.WITHIN_WEEK,
    tanRetention = TanRetention.TANS_EASILY,
  }) => {
    const newAdjustmentFactors = { cautionLevel, tanLevel, tanRecency, tanRetention };
    saveAdjustmentFactorsToCache(newAdjustmentFactors);
    setAdjustmentFactors(newAdjustmentFactors);
  };

  return (
    <UvContext.Provider
      value={{
        skinType,
        saveSkinType,
        adjustmentFactors,
        saveAdjustmentFactors,
        uvIndex,
        maxUv,
        maxUvTime,
        uvForecast,
        isUvLoaded,
      }}
    >
      {children}
    </UvContext.Provider>
  );
};

// Custom hook to use the UvContext in components
export const useUvContext = () => {
  return useContext(UvContext);
};
