import React from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';

const ExposureGuideContent = () => {
  return (
    <div>
      <h1>Safe Sun Exposure Guide</h1>

      <p>
        Safe sun exposure time is determined by factors such as your skin type, UV index,
        recent sun exposure, altitude, latitude, and time of year. We take a personalized
        approach to sun exposure, using the Minimal Erythemal Dose (MED) as the limiting
        factor. MED is the amount of UV radiation your skin can tolerate before it shows
        visible signs of redness. Not only is this reddening harmless, it is actually
        beneficial, so don't be afraid of getting a little red. However, it's important to
        note that this redness, which results from increased blood flow, does not appear
        immediately upon reaching the MED. Typically, it starts to develop **1 to 3
        hours** after exposure ends, as your skin reacts to the dose of UV radiation it
        received.
      </p>

      <h2>Recognizing Early Warning Signs of Reaching Your MED</h2>
      <p>
        Although the visible redness of reaching your MED may not appear immediately,
        there are several warning signs that indicate you've likely reached your limit for
        safe sun exposure. Paying attention to these signs can help you avoid
        overexposure:
      </p>
      <div className="custom-list">
        <div className="custom-list-item">
          <strong>Warmth or Heat on the Skin:</strong> Your skin may start to feel
          noticeably warmer, even though it isn't red or painful yet. This warmth is a
          result of increased blood flow.
        </div>
        <div className="custom-list-item">
          <strong>Mild Tingling or Sensitivity:</strong> Some people experience a slight
          tingling sensation or increased sensitivity in the skin.
        </div>
        <div className="custom-list-item">
          <strong>Tightness or Dryness:</strong> If your skin starts feeling tight or dry,
          it might indicate dehydration from sun exposure.
        </div>
        <div className="custom-list-item">
          <strong>Slight Darkening or Freckling:</strong> You may notice your skin
          becoming slightly darker or freckles appearing more pronounced. This darkening
          indicates increased melanin production, signaling that you are approaching your
          MED.
        </div>
        <div className="custom-list-item">
          <strong>Skin Temperature Disparity:</strong> Compare a sun-exposed area of your
          skin to an unexposed area. If the exposed area feels significantly warmer, it's
          a sign that you are nearing your MED.
        </div>
        <div className="custom-list-item">
          <strong>Subtle Flush or Pinkish Tone:</strong> If you have fair skin, you may
          see a very subtle flush developing.
        </div>
        <div className="custom-list-item">
          <strong>Increased Sensitivity or Mild Discomfort:</strong> Your skin may become
          more sensitive to touch or feel slightly uncomfortable, indicating that you've
          absorbed a significant amount of UV radiation.
        </div>
      </div>

      <h2>Understanding MED and Erythema</h2>
      <p>
        As mentioned above, your skin's sensitivity to UV radiation is quantified by the{' '}
        <strong>Minimal Erythemal Dose (MED)</strong>; the maximum amount of sun you can
        handle before visible signs of redness, or <strong>erythema</strong>, sets in. Not
        all redness indicates skin damage.
        <strong> Level 0 erythema</strong> is a result of increased blood flow to the skin
        due to the release of <strong>nitric oxide</strong>, which occurs in response to
        UV exposure, particularly UV-A. This initial redness is a sign of vasodilation,
        not sunburn, and it typically fades quickly after sun exposure.
      </p>
      <p>
        Vasodilation is beneficial because it reduces your blood pressure and is
        associated with a lower incidence of cardiovascular events such as heart attack
        and stroke. While nitric oxide-induced vasodilation is beneficial, continued
        exposure beyond this point can lead to more intense levels of erythema, including
        sunburn, where skin damage occurs. It's essential to monitor your skin for signs
        of higher-level erythema, where the redness persists, and the skin feels hot or
        painful to the touch, and get into the shade before this occurs.
      </p>
      <p>
        Everyone's skin reacts differently to UV radiation, and these calculations serve
        only as guidelines. It is crucial to monitor your body's response to the sun when
        spending extended time in direct sunlight. Adjust your sun exposure time based on
        how your skin feels and reacts. Always be mindful of signs like redness or
        discomfort, and protect your skin accordingly. The face and neck are three times
        more sensitive to UV than the rest of your body, so wear a hat or take frequent
        shade breaks to protect yourself. There is no need to jump straight to maximum
        exposure time; feel free to ease your way in. For example, you can start by
        cutting the recommended exposure time in half and gradually increasing, paying
        careful attention to how your body reacts.
      </p>

      <h2>How We Calculate Your Safe Sun Exposure Time</h2>
      <p>We calculate the safe exposure time using the following factors:</p>
      <div className="custom-list">
        <div className="custom-list-item">
          <strong>UV Index:</strong> A measure of the sun's UV radiation. The higher the
          UV index, the stronger the radiation.
        </div>
        <div className="custom-list-item">
          <strong>Altitude:</strong> Higher altitudes increase UV exposure, roughly 10%
          for every 1,000 meters.
        </div>
        <div className="custom-list-item">
          <strong>Latitude:</strong> The closer you are to the equator, the stronger the
          UV radiation. We adjust for low, mid, and high latitudes.
        </div>
        <div className="custom-list-item">
          <strong>Time of Year:</strong> UV radiation is stronger during summer months.
          Depending on whether you are in the northern or southern hemisphere, we adjust
          for the current season.
        </div>
        <div className="custom-list-item">
          <strong>Adjustment Factors:</strong> We also consider personal sun exposure
          factors, such as your caution level, tan level, tan recency, and tan retention.
          These adjustments help provide a more accurate estimate of your skin's UV
          tolerance.
        </div>
      </div>

      <h2>Guideline Limitations</h2>
      <p>
        These exposure time estimates provide a starting point for safe sun exposure.
        However, every individual’s skin responds differently to UV radiation, and factors
        like hydration, medications, diet, or pre-existing health conditions may also
        influence your sensitivity to the sun. Always prioritize your body’s unique
        reactions, adjust your time in the sun accordingly, and remember—this is for
        educational purposes only. This is not medical advice.
      </p>
    </div>
  );
};

export default function ExposureGuideModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  const modalResultsClass = darkTheme ? 'modal-results modal-dark' : 'modal-results';

  return (
    <Modal
      show={isModalOpen(ModalTypes.ExposureGuide)}
      darkTheme={darkTheme}
      sunType={sunType}
      onClose={closeModal}
    >
      <ExposureGuideContent />
    </Modal>
  );
}
