import React, { createContext, useContext, useEffect, useState } from 'react';
import packageJson from '../../package.json';
import semver from 'semver';
import {
  deleteLocationDataFromCache,
  loadDataFromCache,
  saveDataToCache,
} from '../utils/local-storage';

const VersionContext = createContext();
const APP_VERSION_KEY = 'APP_VERSION';
const BROKEN_LOCATION_VERSION = '0.5.3';

export const VersionProvider = ({ children }) => {
  const [appVersion, setAppVersion] = useState(null);
  const [cachedVersion, setCachedVersion] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadVersion = async () => {
      const currentAppVersion = packageJson.version;
      setAppVersion(currentAppVersion);

      const cachedVersionData = await loadDataFromCache(APP_VERSION_KEY);
      setCachedVersion(cachedVersionData);

      if (!cachedVersionData || semver.gt(currentAppVersion, cachedVersionData)) {
        await updateVersion(currentAppVersion);

        if (!cachedVersionData || semver.lt(cachedVersionData, BROKEN_LOCATION_VERSION)) {
          console.log('Deleting location data to fix broken events page');
          await deleteLocationDataFromCache();
        }
      }

      setLoading(false); // Set loading to false once everything is resolved
    };

    loadVersion();
  }, []);

  const updateVersion = async (newVersion) => {
    setAppVersion(newVersion);
    await saveDataToCache(APP_VERSION_KEY, newVersion);
  };

  if (loading) {
    return null; // Render nothing or a loading spinner while loading
  }

  return (
    <VersionContext.Provider value={{ version: appVersion, updateVersion }}>
      {children}
    </VersionContext.Provider>
  );
};

export const useVersion = () => {
  return useContext(VersionContext);
};
