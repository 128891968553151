// Import Luxon (you'll need to install it via npm if you're using Node.js: npm install luxon)
import axios from 'axios';
import logger from '../utils/logger';
import { loadUvDataFromCache, saveUvDataToCache } from '../utils/local-storage';
import { getFormattedTime, isToday } from '../utils/time';
import { UVRiskLevels } from '../utils/uv-content';

const { DateTime } = require('luxon');

const OpenUvAPIKey = 'openuv-1mxfsvrm1zez9ip-io';

export const UvUpdateInterval = 60 * 1000 * 30;

export const UvDataTypes = {
  CURRENT: 'current',
  FORECAST: 'forecast',
};

export async function fetchUVIndex(latitude, longitude, displayDate) {
  const openUVURL = `https://api.openuv.io/api/v1/uv`;

  try {
    // Attempt to load cached data
    const cachedData = await loadUvDataFromCache(
      latitude,
      longitude,
      displayDate,
      UvDataTypes.CURRENT,
    );

    if (cachedData) {
      console.log('Returning cached current UV data');
      return cachedData;
    }

    // If no valid cache, make API request
    const response = await axios.get(openUVURL, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': OpenUvAPIKey,
      },
      params: {
        lat: latitude,
        lng: longitude,
      },
    });

    const data = response.data;

    if (data && data.result) {
      const { uv: uvIndex, uv_max: maxUv, uv_max_time: maxUvTime } = data.result;
      console.warn('UV data found', uvIndex, maxUv, maxUvTime);

      // Save the new data to cache
      await saveUvDataToCache(
        latitude,
        longitude,
        { uvIndex, maxUv, maxUvTime },
        displayDate,
        UvDataTypes.CURRENT,
      );

      return { uvIndex, maxUv, maxUvTime };
    } else {
      console.warn('No UV data found for the given location.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching UV data:', error.message);
  }
}

export async function fetchUVForecast(latitude, longitude, displayDate, timezone) {
  const openUVForecastURL = `https://api.openuv.io/api/v1/forecast`;

  try {
    // Attempt to load cached data
    const cachedData = await loadUvDataFromCache(
      latitude,
      longitude,
      displayDate,
      UvDataTypes.FORECAST,
    );

    if (cachedData) {
      console.log('Returning cached UV forecast data');
      return cachedData;
    }

    // If no valid cache, make API request
    const response = await axios.get(openUVForecastURL, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': OpenUvAPIKey,
      },
      params: {
        lat: latitude,
        lng: longitude,
        dt: displayDate.toISO({ includeOffset: false }),
      },
    });

    const data = response.data;

    if (data && data.result && data.result.length > 0) {
      // Assuming 'result' is an array of UV forecasts
      const forecast = data.result.map((item) => ({
        uvIndex: item.uv,
        uvTime: DateTime.fromISO(item.uv_time).setZone(timezone),
      }));
      console.warn('UV forecast found', forecast);

      // Save the new data to cache
      await saveUvDataToCache(
        latitude,
        longitude,
        forecast,
        displayDate,
        UvDataTypes.FORECAST,
      );

      return forecast;
    } else {
      console.warn('No UV forecast data found for the given location.');
      return [];
    }
  } catch (error) {
    console.error('Error fetching UV forecast data:', error);
    return [];
  }
}
