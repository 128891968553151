import { useEffect, useRef } from 'react';
import { CircadianEventType } from './circadian_event';
import { getSunTypeGradient } from '../domain/sun-type';

const CircadianEventSelect = ({
  onEventSelect,
  darkTheme,
  sunType,
  isToday,
  onClickOutside,
}) => {
  const gradient = getSunTypeGradient(sunType);
  const componentRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        onClickOutside?.();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);

  const handleSelectChange = (selectedEvent) => {
    onEventSelect(selectedEvent);
  };

  return (
    <ul
      ref={componentRef}
      className={`select-time ${darkTheme ? 'select-time-dark' : 'select-time-light'}`}
      style={{
        background: gradient,
        zIndex: 100,
      }}
    >
      {isToday && <li onClick={() => handleSelectChange('')}>Current Time</li>}
      {Object.keys(CircadianEventType).map((eventKey) => {
        const event = CircadianEventType[eventKey];

        return (
          <li key={eventKey} onClick={() => handleSelectChange(event)}>
            {CircadianEventType[eventKey]}
          </li>
        );
      })}
    </ul>
  );
};

export default CircadianEventSelect;
