import React from 'react';

import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';

import '../../css/Compass.css';
import { useOrientation } from '../../providers/orientation-provider';

const CompassTroubleShooting = () => {
  const { requestPermission } = useOrientation();

  return (
    <div className="troubleshooting-container">
      <h2 className="troubleshooting-title">Compass Troubleshooting Guide</h2>
      <p className="troubleshooting-intro">
        If your compass isn't working correctly, try the following steps to resolve the
        issue:
      </p>
      <ol className="troubleshooting-steps">
        <li>
          <strong>Ensure Permissions Are Granted:</strong> Some devices require you to
          grant permission to access orientation sensors. For iOS devices, make sure you
          see a prompt asking for permission, click the active button below, or go to your
          settings to enable it manually.
          <br />
          <button className="gradient-button button-small" onClick={requestPermission}>
            Activate Compass
          </button>
        </li>
        <li>
          <strong>Use a Secure Connection:</strong> Many browsers require your app to be
          served over HTTPS to access device orientation data. Make sure you are using a
          secure connection.
        </li>
        <li>
          <strong>Calibrate Your Device:</strong> Calibration may be required to improve
          the accuracy of your compass. You can do this by moving your device in a
          figure-eight motion until the compass stabilizes.
        </li>
        <li>
          <strong>Check Device Settings:</strong> Some browsers and devices have settings
          that control access to the orientation and motion sensors. Ensure that these
          settings are enabled. For iOS, go to{' '}
          <em>Settings &gt; Safari &gt; Motion & Orientation Access</em> and turn it on.
        </li>
        <li>
          <strong>Try Different Devices or Browsers:</strong> Device compatibility varies,
          and some older devices or browsers may not fully support compass or orientation
          data. Try testing with another device or browser to see if the issue persists.
        </li>
        <li>
          <strong>Reload the App:</strong> Sometimes, simply reloading the page or
          restarting the app can help reset any sensor issues and get the compass working
          again.
        </li>
      </ol>
      <p className="troubleshooting-outro">
        If you continue to experience issues, make sure your device's hardware supports
        orientation sensors and is functioning properly.
      </p>
    </div>
  );
};

export default function CompassTroubleShootingModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  return (
    <Modal
      show={isModalOpen(ModalTypes.CompassTroubleShooting)}
      darkTheme={darkTheme}
      sunType={sunType}
      onClose={closeModal}
    >
      <CompassTroubleShooting />
    </Modal>
  );
}
