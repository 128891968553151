import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DefaultLocationData } from '../domain/location';
import {
  fetchLocationData,
  getCoordinates,
  getGeolocation,
  getLocationFromCoords,
  getTimeZone,
  isDefaultCoordinates,
  fetchElevation,
} from '../clients/geo';
import { adjustDay, formatDayTimeAndZone, isDateSame } from '../utils/time';
import { useCurrentTime } from '../hooks/use-current-time';
import { Days } from '../utils/time';
import {
  getSunTimes,
  useSunTimes,
  useSunPosition,
  useMoonPosition,
} from '../hooks/use-sun-times';
import { useMoonPhase } from '../hooks/use-moon-phase';
import { CircadianEventType } from '../components/circadian_event';
import logger from '../utils/logger';
import {
  loadDataFromCache,
  loadLocationDataFromCache,
  saveDataToCache,
  saveLocationDataToCache,
} from '../utils/local-storage';
import { sleep } from '../utils/async';

// Create the context for the solar clock
const SolarClockContext = createContext();

export const useSolarClock = () => {
  // Custom hook to access context values
  const context = useContext(SolarClockContext);
  if (!context) {
    throw new Error('useSolarClock must be used within a SolarClockProvider');
  }
  return context;
};

export const SolarClockProvider = ({ children }) => {
  const [isClockDataLoaded, setIsClockDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(DefaultLocationData.coordinates);
  const [location, setLocation] = useState(DefaultLocationData.location);
  const [timezone, setTimezone] = useState(DefaultLocationData.timezone);
  const [elevation, setElevation] = useState(DefaultLocationData.elevation);
  const [displayDate, setDisplayDate] = useState(Days.Today(timezone));
  const [activeEvent, setActiveEvent] = useState();
  const [isLocationDetectionRequired, setIsLocationDetectionRequired] = useState(false);
  const [detectLocationFailed, setDetectLocationFailed] = useState(false);
  const { currentTime, startClock, stopClock, syncCurrentTime, isClockStopped } =
    useCurrentTime(timezone);

  function employDefaultLocation() {
    logger.info('Employing default location');
    setLocation(DefaultLocationData.location);
    setCoordinates(DefaultLocationData.coordinates);
    setTimezone(DefaultLocationData.timezone);
    setElevation(DefaultLocationData.elevation);
    setIsClockDataLoaded(true);
  }

  async function retrieveLocation() {
    try {
      let locationData = await loadLocationDataFromCache();

      if (locationData) {
        const { location, coordinates: coords, timezone: tz, elevation } = locationData;
        saveLocation({
          newLocation: location,
          newCoordinates: coords,
          newTimezone: tz,
          newElevation: elevation,
        });
      } else {
        setIsLocationDetectionRequired(true);
        setIsLoading(true);
        locationData = await fetchLocationData();
        const { location, coordinates: coords, timezone: tz, elevation } = locationData;
        saveLocation({
          newLocation: location.displayName,
          newCoordinates: coords,
          newTimezone: tz,
          newElevation: elevation,
        });
      }
      setIsLoading(false);
      setIsClockDataLoaded(true);
    } catch (e) {
      setIsLoading(false);
      logger.warn('Error detecting location', e.message);
      setDetectLocationFailed(true);
      employDefaultLocation();
    }
  }

  // Use Effect for obtaining coordinates and updating location
  useEffect(() => {
    retrieveLocation();
  }, []);

  const sunTimes = useSunTimes(coordinates, displayDate, timezone);
  const sunPosition = useSunPosition(coordinates, displayDate);
  const moonPhase = useMoonPhase(coordinates, displayDate);
  const moonPosition = useMoonPosition(coordinates, displayDate);

  useEffect(() => {
    if (!isClockStopped && isDateSame(currentTime, Days.Today(timezone))) {
      // logger.('Updating display date to current time');
      setDisplayDate(currentTime);
    }
  }, [currentTime, timezone]);

  useEffect(() => {
    syncCurrentTime();
  }, [coordinates.latitude, coordinates.longitude, location, timezone]);

  const isDateToday = useCallback(() => {
    return isDateSame(displayDate, Days.Today(timezone));
  }, [displayDate, timezone]);

  const isDateTomorrow = useCallback(() => {
    return isDateSame(displayDate, Days.Tomorrow(timezone));
  }, [displayDate, timezone]);

  const handleDisplayDateChange = (newDate) => {
    setDisplayDate(newDate);
  };

  const findLocation = async (location) => {
    setIsLoading(true);
    const coordinates = await getCoordinates(location);
    let timezone;
    if (!isDefaultCoordinates(coordinates)) {
      timezone = await getTimeZone(coordinates.latitude, coordinates.longitude);
    } else {
      timezone = 'America/Los_Angeles';
    }
    setIsLoading(false);
    return { location, coordinates, timezone };
  };

  function saveLocation({ newLocation, newCoordinates, newTimezone, newElevation }) {
    console.log(
      'saving location data',
      newLocation,
      newCoordinates,
      newTimezone,
      newElevation,
    );
    if (newLocation != null) {
      console.log('Setting location', newLocation);
      setLocation(newLocation);
    }
    if (newCoordinates != null) {
      setCoordinates(newCoordinates);
    }
    if (newTimezone != null) {
      setTimezone(newTimezone);
    }
    if (newElevation != null) {
      setElevation(newElevation);
    }
  }

  useEffect(() => {
    // Prepare the location data for saving, defaulting to current state if necessary
    const locationData = {
      location,
      coordinates,
      timezone,
      elevation,
    };

    console.log(
      'Saving location data to cache',
      location,
      coordinates,
      timezone,
      elevation,
    );
    void saveLocationDataToCache(locationData);
  }, [location, coordinates, timezone, elevation]);

  const selectLocation = async (location) => {
    // logger.('Selected Location', location);
    const coordinates = { latitude: location.lat, longitude: location.lon };

    // If the coordinates are default, handle them immediately and return
    if (isDefaultCoordinates(coordinates)) {
      saveLocation({
        newLocation: location.displayName,
        newCoordinates: coordinates,
        newTimezone: 'America/New_York',
        newElevation: 0,
      });
      setDetectLocationFailed(false);
      return;
    }

    // Fetch elevation separately without blocking or affecting the loading state, elevation is not essential
    fetchElevation(coordinates.latitude, coordinates.longitude)
      .then((elevation) => {
        saveLocation({
          newElevation: elevation,
        });
      })
      .catch((error) => {
        console.error('Error fetching elevation:', error);
      });

    try {
      setIsLoading(true);
      // Fetch the timezone and update via saveLocation
      const timezone = await getTimeZone(coordinates.latitude, coordinates.longitude);
      saveLocation({
        newLocation: location.displayName,
        newCoordinates: coordinates,
        newTimezone: timezone,
      });
      setDetectLocationFailed(false);
    } catch (error) {
      console.error('Error fetching timezone:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isNight = (sunTime) => {
    const { sunrise, sunset } = sunTimes;
    return sunTime < sunrise || sunTime > sunset;
  };

  function activateNullEvent(eventDate) {
    // logger.('\n\n\n');
    const eventTime = eventDate || displayDate;
    if (isDateSame(eventTime, Days.Today(timezone))) {
      // logger.('activating null event for today');
      setDisplayDate(currentTime);
      setActiveEvent();
      startClock();
    } else {
      // logger.('activating null event for another day');
      setDisplayDate(sunTimes.sunrise);
      setActiveEvent(CircadianEventType.Sunrise);
    }
  }

  function getEventTime(eventLabel, eventDate) {
    const [eventKey] = Object.entries(CircadianEventType).find(
      ([key, value]) => eventLabel === value,
    );
    // logger.('eventKey', eventKey);

    const camelCaseKey = eventKey.charAt(0).toLowerCase() + eventKey.slice(1);
    const adjustedSunTimes = eventDate
      ? getSunTimes(coordinates, eventDate, timezone)
      : sunTimes;
    return adjustedSunTimes[camelCaseKey];
  }

  const activateEvent = (eventLabel, eventDate) => {
    if (eventLabel) {
      let activeLabel = eventLabel;
      let eventTime = getEventTime(eventLabel, eventDate);
      if (eventTime.invalid) {
        activeLabel = CircadianEventType.SolarNoon;
        eventTime = getEventTime(activeLabel, eventDate);
      }
      const adjustedEventDate = eventDate ? adjustDay(eventDate, eventTime) : eventTime;

      if (adjustedEventDate) {
        setActiveEvent(activeLabel);
        setDisplayDate(adjustedEventDate);
        stopClock();
        return;
      }
    }
    activateNullEvent(eventDate);
  };

  const clearEvent = (eventDate) => {
    activateNullEvent(eventDate);
  };

  // Provide context to children components
  return (
    <SolarClockContext.Provider
      value={{
        coordinates,
        location,
        timezone,
        elevation,
        currentTime,
        displayDate,
        today: Days.Today(timezone),
        tomorrow: Days.Tomorrow(timezone),
        sunTimes,
        sunPosition,
        moonPhase,
        moonPosition,
        isDateToday,
        isDateTomorrow,
        handleDisplayDateChange,
        isLocationLoading: isLoading,
        findLocation,
        selectLocation,
        isNight,
        activeEvent,
        activateEvent,
        clearEvent,
        isClockDataLoaded,
        detectLocationFailed,
        isLocationDetectionRequired,
      }}
    >
      {children}
    </SolarClockContext.Provider>
  );
};
