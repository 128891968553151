import React, { useEffect, useState } from 'react';
import { useSolarClock } from '../providers/solar-clock-provider';
import DateSelector from './date_selector';
import { usePage, Pages } from '../providers/page-provider';
import LocationSearchModal from './modals/location-search-modal';
import SignUpModal from './modals/sign-up';
import ExposureGuideModal from './modals/exposure-guide';
import SkinTypeModal from './modals/skin-type-modal';
import CompassTroubleShootingModal from './modals/compass-trouble-shooting';
import SunGazingModal from './modals/sun-gazing-modal';
import { CircadianEventType } from './circadian_event';
import { ModalTypes, useModal } from '../providers/modal-provider';
import DebugModal from './modals/debug-modal';
import { useAuth } from '../providers/auth-provider';
import { useInfoPanel } from './info-panel';
import HamburgerMenu from './hamburger-menu';
import { HeaderTime } from './floating-time';

import '../css/Header.css';
import LocationTroubleShootingModal from './modals/location-troubleshooting-modal';
import AdjustmentFactorsModal from './modals/adjustment-factors';

export function HeaderTop({ isLoaded, darkTheme, sunType }) {
  const { siteMenuItems, goToPage } = usePage();
  const { hideInfoPanel } = useInfoPanel();

  const headerColor = darkTheme ? 'dark-mode-text-color' : '';
  const headerClass = isLoaded
    ? `${headerColor} header-top-base header-top-space-between`
    : `${headerColor} header-top-base header-top-centered`;
  const titleClass = darkTheme ? 'title-dark' : 'title-light';

  const navigateHome = () => {
    hideInfoPanel();
    goToPage(Pages.Home);
  };

  return (
    <div className={`header-top ${headerClass}`}>
      <div className={titleClass}>
        <h1 onClick={navigateHome}>Sunlight is Life</h1>
      </div>
      {isLoaded && (
        <HamburgerMenu
          menuItems={siteMenuItems}
          darkTheme={darkTheme}
          sunType={sunType}
        />
      )}
    </div>
  );
}

function AppHeader({ darkTheme, sunType, isLoaded = false }) {
  const [isControlsVisible, setIsControlsVisible] = useState(false);
  const { displayDate, activateEvent, isDateToday } = useSolarClock();
  const { goToPage, siteMenuItems, isActivePage } = usePage();
  const { openModal } = useModal();
  const { isAuthenticated, user } = useAuth();

  const headerClass = darkTheme ? 'header dark-mode-text-color' : 'header';
  const timeClass = darkTheme ? 'headerTime dark-mode-text-color' : 'headerTime';

  useEffect(() => {
    if (!isAuthenticated || (user.loaded && !user.id)) {
      openModal(ModalTypes.InviteOnly);
    }
  }, [isAuthenticated, user.loaded, user.id]);

  const openDateSelector = () => {
    setIsControlsVisible(true);
  };
  const closeDateSelector = () => setIsControlsVisible(false);

  const handleDateChange = (newDate) => {
    activateEvent(CircadianEventType.Sunrise, newDate);
  };

  return (
    <div className={headerClass}>
      {/* Top section containing the title and hamburger menu */}
      <HeaderTop
        isLoaded={isLoaded}
        siteMenuItems={siteMenuItems}
        darkTheme={darkTheme}
        sunType={sunType}
      />

      {/* Bottom section containing the clock label */}
      {isLoaded && !isControlsVisible && !isActivePage(Pages.Home) && (
        <div className="header-bottom">
          <HeaderTime sunType={sunType} openDateSelector={openDateSelector} />
        </div>
      )}
      {isLoaded && isControlsVisible && (
        <div className={timeClass}>
          <DateSelector
            onChange={handleDateChange}
            displayDate={displayDate}
            onClose={closeDateSelector}
          />
        </div>
      )}

      {/* Modals */}
      <LocationSearchModal darkTheme={darkTheme} sunType={sunType} />
      <SignUpModal darkTheme={darkTheme} sunType={sunType} />
      <DebugModal darkTheme={darkTheme} sunType={sunType} />
      <ExposureGuideModal darkTheme={darkTheme} sunType={sunType} />
      <SkinTypeModal darkTheme={darkTheme} sunType={sunType} />
      <AdjustmentFactorsModal darkTheme={darkTheme} sunType={sunType} />
      <CompassTroubleShootingModal darkTheme={darkTheme} sunType={sunType} />
      <SunGazingModal darkTheme={darkTheme} sunType={sunType} />
      <LocationTroubleShootingModal darkTheme={darkTheme} sunType={sunType} />
    </div>
  );
}

export default AppHeader;
