import React from 'react';
import { useSolarClock } from '../providers/solar-clock-provider';
import { LocationSearch } from '../components/modals/location-search-modal';

function LocationPage({ children, closeModal, darkTheme }) {
  const { detectLocationFailed } = useSolarClock();

  if (detectLocationFailed) {
    const localDarkTheme = darkTheme ? darkTheme : true;
    const contentClass = localDarkTheme ? 'dark-mode-text-color' : '';
    return (
      <div className={contentClass}>
        <LocationSearch darkTheme={localDarkTheme} onSelect={closeModal} />
      </div>
    );
  }

  return children;
}

export default LocationPage;
