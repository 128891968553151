import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';

import infoPanelBriefContentMap from '../data/info-panel-content-brief';
import infoPanelExpandedContentMap from '../data/info-panel-content';
import '../css/Tooltip.css';
import useBrowserInfo from '../hooks/use-browser-info';
import SlidingPanel from './SlidingPanel';
import { useSolarClock } from '../providers/solar-clock-provider';

const InfoPanelContext = createContext();

export const InfoPanelState = {
  Closed: 'closed',
  Preview: 'preview',
  Full: 'full',
};

const DefaultContent = {
  content: 'No content available',
  position: 'nadir',
  state: InfoPanelState.Closed,
};

export const InfoPanelProvider = ({ children }) => {
  const { isMobileLayout } = useBrowserInfo();
  const [infoPanel, setInfoPanel] = useState(DefaultContent);
  const [isDetailedInfo, setDetailedInfo] = useState(false);
  const {
    sunTimes: { uvbRise },
  } = useSolarClock();

  const getPanelContent = useCallback(
    (name) => {
      let key = name;

      if (uvbRise === undefined) {
        key = `${name} No UVB`;
      }

      if (isDetailedInfo) {
        return infoPanelExpandedContentMap[key] || infoPanelExpandedContentMap[name];
      } else {
        const briefContent =
          infoPanelBriefContentMap[key] ||
          infoPanelBriefContentMap[name] ||
          DefaultContent;
        const content = `
          <strong>What is it?:</strong>
          <div>${briefContent.definition}</div>
          <strong>What to do?</strong>
          <div>${briefContent.action}</div>
          <strong>Why do it?</strong>
          <div>${briefContent.explanation}</div>
        `;
        return {
          ...briefContent,
          content,
        };
      }
    },
    [isDetailedInfo, uvbRise],
  );

  useEffect(() => {
    if (infoPanel.name !== DefaultContent.name) {
      showInfoPanel(infoPanel.name);
    }
  }, [isDetailedInfo]);

  const showInfoPanelPreview = (name) => {
    const state = InfoPanelState.Preview;
    const infoPanelState = {
      name,
      content: '',
      position: undefined,
      state,
      visible: state === InfoPanelState.Full,
    };
    setInfoPanel(infoPanelState);
    setTimeout(() => showInfoPanel(name), 550);
  };

  const handleToggleContent = (name) => {
    setDetailedInfo((prevVal) => !prevVal);
  };

  const showInfoPanel = (name, type = 'Expandable', customContent) => {
    const { content, position } = getPanelContent(name);
    const isExpandable = type === 'Expandable';
    const infoPanelContent = (
      <div>
        {!customContent && <h3>{name}</h3>}
        {customContent ? (
          <div dangerouslySetInnerHTML={{ __html: customContent }} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )}
        {!isDetailedInfo && isExpandable && (
          <div className="more-less-info" onClick={() => handleToggleContent(name)}>
            Read more about the science &gt;&gt;
          </div>
        )}
        {isDetailedInfo && isExpandable && (
          <div className="more-less-info" onClick={() => handleToggleContent(name)}>
            &lt;&lt; Go back
          </div>
        )}
      </div>
    );
    const state = InfoPanelState.Full;
    const infoPanelState = {
      name,
      content: infoPanelContent,
      position,
      state,
      visible: state === InfoPanelState.Full,
    };
    setInfoPanel(infoPanelState);
  };

  const hideInfoPanel = () => {
    const state = InfoPanelState.Closed;
    setInfoPanel(DefaultContent);
    setDetailedInfo(false);
  };

  return (
    <InfoPanelContext.Provider
      value={{ infoPanel, showInfoPanelPreview, showInfoPanel, hideInfoPanel }}
    >
      {children}
      {
        <SlidingPanel
          name={infoPanel.name}
          content={infoPanel.content}
          state={infoPanel.state}
        />
      }
    </InfoPanelContext.Provider>
  );
};

const Tooltip = ({ content, position }) => {
  return <div className={`tooltip ${position}`}>{content}</div>;
};

export const useInfoPanel = () => useContext(InfoPanelContext);
