import React, { useState } from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';
import { useSolarClock } from '../../providers/solar-clock-provider';
import { Days } from '../../hooks/use-current-time';
import { formatDayTimeAndZone } from '../../utils/time';
import { SunTypes } from '../../domain/sun-type';
import { messages, clearMessages } from '../../utils/logger';
import '../../css/Debug.css';

export default function DebugModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();
  const [activeTab, setActiveTab] = useState('Debug Info');

  const {
    coordinates,
    location,
    timezone,
    currentTime,
    displayDate,
    today,
    tomorrow,
    sunTimes,
    moonPhase,
    activeEvent,
  } = useSolarClock();

  return (
    <Modal
      show={isModalOpen(ModalTypes.Debug)}
      onClose={closeModal}
      darkTheme={false}
      sunType={SunTypes.Red}
    >
      <div className="debug-tabs">
        <button onClick={() => setActiveTab('Debug Info')}>Debug Info</button>
        <button onClick={() => setActiveTab('Sun Info')}>Sun Data</button>
      </div>
      {activeTab === 'Debug Info' && (
        <div className="debug-info-container">
          <div className="debug-messages">
            {messages.length > 0 ? (
              messages.map((msg, index) => (
                <div key={index}>
                  <span>[{msg.type}]</span> <span>{msg.message}</span>
                </div>
              ))
            ) : (
              <div>No debug messages available.</div>
            )}
          </div>
          <button onClick={clearMessages}>Clear Messages</button>
        </div>
      )}
      {activeTab === 'Sun Info' && (
        <div className="debug-container">
          <div>
            <span>Location:</span>
            <span>{JSON.stringify(location, undefined, 2)}</span>
          </div>
          <div>
            <span>Timezone:</span>
            <span>{timezone}</span>
          </div>
          <div>
            <span>Coordinates:</span>
            <span>{JSON.stringify(coordinates, undefined, 2)}</span>
          </div>
          <div>
            <span>Current Time:</span>
            <span>{formatDayTimeAndZone(currentTime)}</span>
          </div>
          <div>
            <span>Display Date:</span>
            <span>{formatDayTimeAndZone(displayDate)}</span>
          </div>
          <div>
            <span>Today:</span>
            <span>{formatDayTimeAndZone(today)}</span>
          </div>
          <div>
            <span>Tomorrow:</span>
            <span>{formatDayTimeAndZone(tomorrow)}</span>
          </div>
          <div>
            <span>Active Event:</span>
            <span>{activeEvent}</span>
          </div>
          <div>
            <span>Sun Times:</span>
            <span>{JSON.stringify(sunTimes, undefined, 2)}</span>
          </div>
          <div>
            <span>MoonPhase:</span>
            <span>{JSON.stringify(moonPhase, undefined, 2)}</span>
          </div>
        </div>
      )}
    </Modal>
  );
}
