import { getDateTime, getFormattedTime, isValidDate } from '../utils/time';
import React, { useMemo } from 'react';
import { useInfoPanel } from './info-panel';
import { SunTypes, getSunTypeGradient, isDaySun } from '../domain/sun-type';
import useBrowserInfo from '../hooks/use-browser-info';

import '../css/CircadianEvents.css';

export const CircadianEventType = {
  Daybreak: 'Daybreak',
  Dawn: 'Dawn',
  Sunrise: 'Sunrise',
  UvaRise: 'UVA Rise',
  UvbRise: 'UVB Rise',
  SolarNoon: 'Solar Noon',
  UvbSet: 'UVB Set',
  UvaSet: 'UVA Set',
  Sunset: 'Sunset',
  Dusk: 'Dusk',
  Nightfall: 'Nightfall',
  Nadir: 'Nadir',
};

const circadianEventToSunTypeMap = {
  [CircadianEventType.Daybreak]: SunTypes.BlueHour,
  [CircadianEventType.Dawn]: SunTypes.Twilight,
  [CircadianEventType.Sunrise]: SunTypes.Red,
  [CircadianEventType.UvaRise]: SunTypes.UVA,
  [CircadianEventType.UvbRise]: SunTypes.UVB,
  [CircadianEventType.SolarNoon]: SunTypes.SolarNoon,
  [CircadianEventType.UvbSet]: SunTypes.UVB,
  [CircadianEventType.UvaSet]: SunTypes.UVA,
  [CircadianEventType.Sunset]: SunTypes.Red,
  [CircadianEventType.Dusk]: SunTypes.Twilight,
  [CircadianEventType.Nightfall]: SunTypes.BlueHour,
  [CircadianEventType.Nadir]: SunTypes.Night,
};

export function getCircadianEventGradient(eventType) {
  const sunType = circadianEventToSunTypeMap[eventType];
  return getSunTypeGradient(sunType) || getSunTypeGradient(SunTypes.Night); // Default to Night gradient if sunType is unknown
}

export const isDayCircadianEvent = (eventType) => {
  const sunType = circadianEventToSunTypeMap[eventType];
  return isDaySun(sunType);
};

export function getSunTypeByCircadianEvent(eventType) {
  return circadianEventToSunTypeMap[eventType] || SunTypes.Night;
}

export function getCircadianEventBySunType(sunType) {
  return (
    Object.entries(circadianEventToSunTypeMap).find(
      ([, mappedSunType]) => mappedSunType === sunType,
    )?.[0] || CircadianEventType.Nadir
  );
}

export function CircadianEvent({ event, sunType, onHover, activeEvent, darkTheme }) {
  const { showInfoPanel } = useInfoPanel();
  const { isMobileLayout } = useBrowserInfo();

  const handleClick = () => {
    if (event.label === activeEvent) {
      showInfoPanel(event.label);
    } else {
      onHover();
    }
  };

  const textMode = darkTheme ? 'dark-mode-text-color' : '';
  const eventType = 'circadian-event';
  const eventClass = event.active
    ? `${eventType} ${eventType}-highlight ${textMode}`
    : `eventType ${textMode}`;

  return (
    <div className={eventClass} onClick={handleClick}>
      <span className="label">{event.label}:</span>
      <span className="date">{getFormattedTime(event.date)}</span>
    </div>
  );
}

export function CircadianEventsList({
  sunTimes,
  timezone,
  onHoverEvent,
  sunElevationTime,
  activeEvent,
  darkTheme,
  showTwilight = false,
}) {
  const events = useMemo(() => {
    const events = generateCircadianEvents(sunTimes).filter(
      (event) =>
        showTwilight ||
        (event.label !== CircadianEventType.Dawn &&
          event.label !== CircadianEventType.Dusk),
    );
    const solarNoon = events.find((e) => e.label === CircadianEventType.SolarNoon);

    let activeEvent = null;
    if (sunElevationTime <= solarNoon.date) {
      activeEvent = findClosestLowerEvent(sunElevationTime, events);
    } else {
      activeEvent = findClosestHigherEvent(sunElevationTime, events);
    }

    // This means the current date in the app is today's date
    if (activeEvent !== null) {
      activeEvent.active = true;
    }
    return events;
  }, [sunTimes, timezone, sunElevationTime]);

  const handleOnHoverEvent = (event) => {
    onHoverEvent(event.label, getDateTime(event.date, timezone));
  };

  return (
    <div className="circadian-events-list">
      {events.map((event, index) => (
        <CircadianEvent
          key={index}
          event={event}
          onHover={() => handleOnHoverEvent(event)}
          activeEvent={activeEvent}
          darkTheme={darkTheme}
        />
      ))}
    </div>
  );
}

export function generateCircadianEvents(sunTimes) {
  const {
    sunrise,
    sunset,
    dawn,
    dusk,
    uvaRise,
    uvaSet,
    uvbRise,
    uvbSet,
    solarNoon,
    nightfall,
    daybreak,
    nadir,
  } = sunTimes;

  const circadianEvents = [
    {
      label: CircadianEventType.Daybreak,
      date: daybreak,
      sunType: SunTypes.BlueHour,
      active: false,
    },
    {
      label: CircadianEventType.Dawn,
      date: dawn,
      sunType: SunTypes.Twilight,
      active: false,
    },
    {
      label: CircadianEventType.Sunrise,
      date: sunrise,
      sunType: SunTypes.Red,
      active: false,
    },
    {
      label: CircadianEventType.UvaRise,
      date: uvaRise,
      sunType: SunTypes.UVA,
      active: false,
    },
    {
      label: CircadianEventType.UvbRise,
      date: uvbRise,
      sunType: SunTypes.UVB,
      active: false,
    },
    {
      label: CircadianEventType.SolarNoon,
      date: solarNoon,
      sunType: SunTypes.SolarNoon,
      active: false,
    },
    {
      label: CircadianEventType.UvbSet,
      date: uvbSet,
      sunType: SunTypes.UVB,
      active: false,
    },
    {
      label: CircadianEventType.UvaSet,
      date: uvaSet,
      sunType: SunTypes.UVA,
      active: false,
    },
    {
      label: CircadianEventType.Sunset,
      date: sunset,
      sunType: SunTypes.Red,
      active: false,
    },
    {
      label: CircadianEventType.Dusk,
      date: dusk,
      sunType: SunTypes.Twilight,
      active: false,
    },
    {
      label: CircadianEventType.Nightfall,
      date: nightfall,
      sunType: SunTypes.BlueHour,
      active: false,
    },
    // {
    //   label: CircadianEventType.Nadir,
    //   date: nadir,
    //   sunType: SunTypes.Night,
    //   active: false,
    // },
  ];
  return circadianEvents.filter((event) => isValidDate(event.date));
}

function findClosestLowerEvent(date, circadianEvents) {
  const sorted = circadianEvents
    .filter((event) => event.date <= date)
    .sort((a, b) => b.date - a.date);
  return sorted[0] || null;
}

function findClosestHigherEvent(date, circadianEvents) {
  return (
    circadianEvents
      .filter((event) => event.date >= date)
      .sort((a, b) => a.date - b.date)[0] || null
  );
}
