import React, { useCallback } from 'react';
import { useSolarClock } from '../providers/solar-clock-provider';
import { ModalTypes, useModal } from '../providers/modal-provider';
import '../css/Nav.css';
import { useAuth } from '../providers/auth-provider';
import useFeature, { FeatureFlags } from '../hooks/use-feature';

const CompassNavigation = ({ darkTheme, isUvLoading }) => {
  const { openModal } = useModal();
  const { user } = useAuth();
  const isFeatureActive = useFeature();
  const openSunGazingModal = useCallback(() => openModal(ModalTypes.SunGazing), []);
  const openLocationModal = useCallback(() => {
    if (isFeatureActive(FeatureFlags.AllowLocationSearch)) {
      openModal(ModalTypes.LocationSearch);
    }
  }, []);
  const { location, isLoading } = useSolarClock();

  const styleSuffix = darkTheme ? '-dark' : '-light';
  const navWrapperClass = `nav-wrapper${styleSuffix}`;
  const navItemClass = `nav-item${styleSuffix}`;
  const navActionItemClass = `nav-item${styleSuffix} underlined nav-item-small`;
  const displayLocation = isLoading ? '--, --' : location;

  return (
    <div className={navWrapperClass}>
      <>
        <div className={navItemClass} onClick={openLocationModal}>
          {displayLocation}
        </div>
        <div className={navActionItemClass} onClick={openSunGazingModal}>
          Sun Gazing
        </div>
      </>
    </div>
  );
};

export default CompassNavigation;
