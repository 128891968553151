import { useEffect, useRef, useState } from 'react';
import { useInterval } from './use-interval';
import { DateTime } from 'luxon';
import { DefaultLocationData } from '../domain/location';
import logger from '../utils/logger';
import { Days } from '../utils/time';

const SixtySeconds = 60 * 1000;

export const useCurrentTime = (timezone) => {
  // Clock ticking function
  const [currentTime, setCurrentTime] = useState(Days.Today(timezone));
  const isPausedRef = useRef(false);

  const count = useInterval(SixtySeconds);

  const syncCurrentTime = () => {
    const now = DateTime.now().setZone(timezone);
    setCurrentTime(now);
  };

  const tickClock = () => {
    if (!isPausedRef.current) {
      syncCurrentTime();
    }
  };

  useEffect(() => {
    tickClock();
  }, [count, timezone]); // Added timezone as a dependency

  useEffect(() => {
    syncCurrentTime();
  }, [timezone]); // Sync time immediately when timezone changes

  const startClock = () => {
    if (isPausedRef.current) {
      //      logger.log('Starting clock');
      isPausedRef.current = false;
      syncCurrentTime();
    }
  };

  const stopClock = () => {
    //    logger.log('Stopping clock');
    isPausedRef.current = true;
  };

  return {
    currentTime,
    startClock,
    stopClock,
    syncCurrentTime,
    isClockStopped: isPausedRef.current,
  };
};
