import { isTimeWithinTimeRange } from '../utils/time';

const NightGradient = [
  { pct: 0, color: { r: 11, g: 61, b: 145 } },
  { pct: 100, color: { r: 10, g: 10, b: 10 } },
];
const BlueHourGradient = [
  { pct: 0, color: { r: 255, g: 69, b: 0 } },
  { pct: 5, color: { r: 173, g: 216, b: 230 } },
  { pct: 30, color: { r: 11, g: 61, b: 145 } },
  { pct: 100, color: { r: 10, g: 10, b: 10 } },
];
const TwilightGradient = [
  { pct: 0, color: { r: 255, g: 69, b: 0 } },
  { pct: 15, color: { r: 255, g: 215, b: 0 } },
  { pct: 30, color: { r: 173, g: 216, b: 230 } },
  { pct: 100, color: { r: 51, g: 51, b: 102 } },
];
const SolarNoonGradient = [
  { pct: 1, color: { r: 173, g: 216, b: 230 } },
  { pct: 35, color: { r: 255, g: 215, b: 0 } },
  { pct: 65, color: { r: 255, g: 215, b: 0 } },
  { pct: 99, color: { r: 173, g: 216, b: 230 } },
];
const UVBGradient = [
  { pct: 10, color: { r: 173, g: 216, b: 230 } },
  { pct: 40, color: { r: 255, g: 215, b: 0 } },
  { pct: 60, color: { r: 255, g: 215, b: 0 } },
  { pct: 90, color: { r: 173, g: 216, b: 230 } },
];
const UVAGradient = [
  { pct: 0, color: { r: 173, g: 216, b: 230 } },
  { pct: 33, color: { r: 255, g: 195, b: 0 } },
  { pct: 66, color: { r: 255, g: 255, b: 153 } },
  { pct: 100, color: { r: 135, g: 206, b: 235 } },
];
const RedGradient = [
  { pct: 0, color: { r: 255, g: 87, b: 51 } },
  { pct: 25, color: { r: 255, g: 195, b: 0 } },
  { pct: 50, color: { r: 255, g: 252, b: 153 } },
  { pct: 100, color: { r: 135, g: 206, b: 235 } },
];

export const SunTypes = {
  Red: 'red',
  UVA: 'uva',
  UVB: 'uvb',
  Night: 'night',
  Twilight: 'twilight',
  BlueHour: 'blue_hour',
  SolarNoon: 'noon',
};

const SolarGradientsBySunType = {
  [SunTypes.Red]: RedGradient,
  [SunTypes.UVA]: UVAGradient,
  [SunTypes.UVB]: UVBGradient,
  [SunTypes.SolarNoon]: SolarNoonGradient,
  [SunTypes.Twilight]: TwilightGradient,
  [SunTypes.BlueHour]: BlueHourGradient,
  [SunTypes.Night]: NightGradient,
};

export const getSolarGradientBySunType = (sunType) => {
  return SolarGradientsBySunType[sunType];
};

export const SunTypeProgression = [
  SunTypes.Night,
  SunTypes.BlueHour,
  SunTypes.Twilight,
  SunTypes.Red,
  SunTypes.UVA,
  SunTypes.UVB,
  SunTypes.SolarNoon,
];

const GradientProgression = [
  NightGradient,
  BlueHourGradient,
  TwilightGradient,
  RedGradient,
  UVAGradient,
  UVBGradient,
  SolarNoonGradient,
];

export function getSunType(sunTimes, currentTime) {
  const {
    sunrise,
    sunset,
    dawn,
    dusk,
    uvaRise,
    uvaSet,
    uvbRise,
    uvbSet,
    solarNoon,
    nightfall,
    daybreak,
    nadir,
  } = sunTimes;
  // logger.log('Checking for sun type with', formatDayTimeAndZone(currentTime));
  if (currentTime > nightfall) {
    // logger.log('currentTime > nightfall', formatDayTimeAndZone(nightfall));
    return SunTypes.Night;
  } else if (currentTime > dusk) {
    // logger.log('currentTime > dusk', formatDayTimeAndZone(dusk));
    return SunTypes.BlueHour;
  } else if (currentTime > sunset) {
    // logger.log('currentTime > sunset', formatDayTimeAndZone(sunset));
    return SunTypes.Twilight;
  } else if (currentTime > uvaSet) {
    // logger.log('currentTime > uvaSet', formatDayTimeAndZone(uvaSet));
    return SunTypes.Red;
  } else if (currentTime > uvbSet) {
    // logger.log('currentTime > uvbSet', formatDayTimeAndZone(uvbSet));
    return SunTypes.UVA;
  } else if (isTimeWithinTimeRange(currentTime, solarNoon, 60)) {
    // logger.log('currentTime ~ solarNoon', formatDayTimeAndZone(solarNoon));
    return SunTypes.SolarNoon;
  } else if (currentTime >= uvbRise) {
    // logger.log('currentTime >= uvbRise', formatDayTimeAndZone(uvbRise));
    return SunTypes.UVB;
  } else if (currentTime >= uvaRise) {
    // logger.log('currentTime >= uvaRise', formatDayTimeAndZone(uvaRise));
    return SunTypes.UVA;
  } else if (currentTime >= sunrise) {
    // logger.log('currentTime >= sunrise', formatDayTimeAndZone(sunrise));
    return SunTypes.Red;
  } else if (currentTime >= dawn) {
    // logger.log('currentTime >= dawn', formatDayTimeAndZone(dawn));
    // logger.log('sunrise time', formatDayTimeAndZone(sunrise));
    return SunTypes.Twilight;
  } else if (currentTime >= daybreak) {
    // logger.log('currentTime >= daybreak', formatDayTimeAndZone(daybreak));
    return SunTypes.BlueHour;
  } else {
    // logger.log('currentTime defaults to night');
    return SunTypes.Night;
  }
}

const Colors = {
  BrightRedOrange: '#FF4500',
  WarmRedOrange: '#FF5733',
  Amber: '#FFBF00',
  VibrantYellow: '#FFC300',
  BrightGold: '#FFD700',
  LightLemonYellow: '#FFFF99',
  PaleYellow: '#FFFC99',
  LightBlue: '#ADD8E6',
  SkyBlue: '#87CEEB',
  DarkSlateBlue: '#333366',
  RoyalBlue: '#0B3D91',
  DeepOceanBlue: '#0A2849',
  MidnightBlue: '#0A0A26',
  PitchBlack: '#0A0A0A',
};

const createLinearGradient = (...colors) =>
  `linear-gradient(to top, ${colors.join(', ')})`;

const SunTypeGradients = {
  [SunTypes.BlueHour]: createLinearGradient(
    `${Colors.BrightRedOrange} 0%`,
    `${Colors.LightBlue} 5%`,
    `${Colors.RoyalBlue} 30%`,
    `${Colors.PitchBlack} 100%`,
  ),
  // Dawn/Dusk
  [SunTypes.Twilight]: createLinearGradient(
    `${Colors.BrightRedOrange} 0%`,
    `${Colors.BrightGold} 15%`,
    `${Colors.LightBlue} 30%`,
    `${Colors.DarkSlateBlue} 100%`,
  ),
  // Sunrise/sunset
  [SunTypes.Red]: createLinearGradient(
    `${Colors.WarmRedOrange} 0%`,
    `${Colors.VibrantYellow} 11%`,
    `${Colors.PaleYellow} 22%`,
    `${Colors.SkyBlue} 100%`,
  ),
  // Early morning/late afternoon
  [SunTypes.UVA]: createLinearGradient(
    `${Colors.Amber} 0%`,
    `${Colors.VibrantYellow} 11%`,
    `${Colors.LightLemonYellow} 26%`,
    `${Colors.SkyBlue} 100%`,
  ),
  // Late morning/Early afternoon
  [SunTypes.UVB]: createLinearGradient(
    `${Colors.LightBlue} 5%`,
    `${Colors.BrightGold} 20%`,
    `${Colors.BrightGold} 30%`,
    `${Colors.LightBlue} 60%`,
  ),
  // Peak of sun in the sky
  [SunTypes.SolarNoon]: createLinearGradient(
    `${Colors.LightBlue} 1%`,
    `${Colors.BrightGold} 45%`,
    `${Colors.LightBlue} 85%`,
  ),
  // Night
  [SunTypes.Night]: createLinearGradient(
    `${Colors.RoyalBlue}`,
    `${Colors.DeepOceanBlue}`,
    `${Colors.MidnightBlue}`,
    `${Colors.PitchBlack}`,
  ),
};

export function getSunTypeGradient(sunType) {
  return SunTypeGradients[sunType] || SunTypeGradients[SunTypes.Night]; // Default to Night gradient if sunType is unknown
}

const NightSunTypeGradients = [SunTypes.Twilight, SunTypes.BlueHour, SunTypes.Night];

export const isDaySun = (sunType) => {
  return !NightSunTypeGradients.includes(sunType);
};
