import React, { useEffect, useState } from 'react';
import { ModalTypes, useModal } from '../../providers/modal-provider';
import Modal from './modal';
import { CautionLevel, TanLevel, TanRecency, TanRetention } from '../../utils/uv-content';
import { useUvContext } from '../../providers/uv-provider';

const AdjustmentFactorsForm = () => {
  const {
    adjustmentFactors: { cautionLevel, tanLevel, tanRecency, tanRetention },
    saveAdjustmentFactors,
  } = useUvContext();
  const [selectedCautionLevel, setSelectedCautionLevel] = useState(cautionLevel);
  const [selectedTanLevel, setSelectedTanLevel] = useState(tanLevel);
  const [selectedTanRecency, setSelectedTanRecency] = useState(tanRecency);
  const [selectedTanRetention, setSelectedTanRetention] = useState(tanRetention);
  const { closeModal } = useModal();

  const handleSelectChange = (setter) => (e) => {
    setter(e.target.value);
  };

  useEffect(() => {
    saveAdjustmentFactors({
      cautionLevel: selectedCautionLevel,
      tanLevel: selectedTanLevel,
      tanRecency: selectedTanRecency,
      tanRetention: selectedTanRetention,
    });
  }, [selectedCautionLevel, selectedTanLevel, selectedTanRecency, selectedTanRetention]);

  return (
    <div className="content">
      <h1>Safe Exposure Adjustment Factors</h1>
      <p>
        The Minimal Erythemal Dose (MED) measures the UV radiation needed to cause
        sunburn. The MED range is based on studies for each Fitzpatrick skin type, but
        factors like past sun exposure and the skin's natural response depending on your
        skin type (tanning, darkening, or freckling), health, diet, and environment can
        all influence where you fall in that range. Adjust the factors below to get a more
        accurate estimate of your current UV tolerance.
      </p>
      <p style={{ fontSize: '11px' }}>
        "Tan" will used on the rest of this page for simplicity, but it also applies to
        freckling or darkening depending on your skin type.
      </p>

      <div style={{ marginBottom: '20px' }}>
        <h2>Caution Level</h2>
        <p>
          The caution level determines how conservatively the Minimal Erythemal Dose (MED)
          is applied. Lower caution levels assume higher MED values, while higher caution
          levels assume lower MED values to provide additional safety.
        </p>
        <label htmlFor="caution-level-select" className="selectListLabel">
          Select your caution level:
        </label>
        <select
          id="caution-level-select"
          value={selectedCautionLevel}
          onChange={handleSelectChange(setSelectedCautionLevel)}
          className="selectListComponent"
        >
          <option value="">Caution Level</option>
          <option value={CautionLevel.Low}>Low</option>
          <option value={CautionLevel.Standard}>Standard</option>
          <option value={CautionLevel.High}>High</option>
        </select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h2>Tan Level</h2>
        <p>
          Tan level indicates how your skin responds to sun exposure, which can increase
          your MED. A deeper response means more protection against UV radiation, thus
          increasing the MED.
        </p>
        <label htmlFor="tan-level-select" className="selectListLabel">
          Select your tan level:
        </label>
        <select
          id="tan-level-select"
          value={selectedTanLevel}
          onChange={handleSelectChange(setSelectedTanLevel)}
          className="selectListComponent"
        >
          <option value="">Tan Level</option>
          <option value={TanLevel.NONE}>None</option>
          <option value={TanLevel.LIGHT}>Light</option>
          <option value={TanLevel.MODERATE}>Moderate</option>
          <option value={TanLevel.DEEP}>Deep</option>
        </select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h2>Tan Recency</h2>
        <p>
          The recency of your tan affects how strong the protection from your tan is.
          Recent tans provide more protection, while older tans may have faded, reducing
          the MED increase.
        </p>
        <label htmlFor="tan-recency-select" className="selectListLabel">
          How recent is your tan?
        </label>
        <select
          id="tan-recency-select"
          value={selectedTanRecency}
          onChange={handleSelectChange(setSelectedTanRecency)}
          className="selectListComponent"
        >
          <option value="">Tan Recency</option>
          <option value={TanRecency.WITHIN_WEEK}>Within the last week</option>
          <option value={TanRecency.WITHIN_MONTH}>Within the last month</option>
          <option value={TanRecency.MORE_THAN_MONTH}>More than a month ago</option>
        </select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h2>Tan Retention</h2>
        <p>
          Tan retention indicates how well your skin holds on to a tan. If your tan fades
          quickly, the MED increase is less significant compared to those who retain their
          tan longer.
        </p>
        <label htmlFor="tan-retention-select" className="selectListLabel">
          How well do you retain a tan?
        </label>
        <select
          id="tan-retention-select"
          value={selectedTanRetention}
          onChange={handleSelectChange(setSelectedTanRetention)}
          className="selectListComponent"
        >
          <option value="">Tan Retention</option>
          <option value={TanRetention.TANS_EASILY}>Retains well</option>
          <option value={TanRetention.FADES_QUICKLY}>Fades quickly</option>
        </select>
      </div>
      <button onClick={closeModal}>Done</button>
    </div>
  );
};

export default function AdjustmentFactorsModal({ darkTheme, sunType }) {
  const { isModalOpen, closeModal } = useModal();

  return (
    <Modal
      show={isModalOpen(ModalTypes.AdjustmentFactors)}
      darkTheme={darkTheme}
      sunType={sunType}
      onClose={closeModal}
    >
      <AdjustmentFactorsForm />
    </Modal>
  );
}
