// browserUtils.js
export function getWindowDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function isTouchScreen() {
  return window.matchMedia('(max-width: 767px) and (pointer: coarse)').matches;
}

export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function isAndroid() {
  return /Android/.test(navigator.userAgent);
}

export function isMobile() {
  return isIOS() || isAndroid() || isTouchScreen();
}

export function isDesktop() {
  return !isMobile();
}

export function isStandalone() {
  return window.matchMedia('(display-mode: standalone)').matches;
}
