import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './auth-provider';
import { ModalTypes, useModal } from './modal-provider';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import { toggleLogging } from '../utils/logger';
import useBrowserInfo from '../hooks/use-browser-info';
import packageJson from '../../package.json';

// Define available pages
export const Pages = {
  Clock: 'clock',
  Uv: 'uv',
  Compass: 'compass',
  LearnMore: 'learn-more',
  Home: 'home',
  ToggleDebug: 'toggle-debug',
  ShowDebug: 'show-debug',
};

const CompassItem = {
  key: Pages.Compass,
  name: 'Compass',
  link: Pages.Compass,
  index: 4,
  siteNav: true,
};

const ToggleDebugItem = {
  key: Pages.ToggleDebug,
  index: 6,
  siteNav: false,
};

const ShowDebugItem = {
  key: Pages.ShowDebug,
  index: 7,
  siteNav: false,
};

const VersionItem = {
  key: 'version',
  name: `Version ${packageJson.version}`,
  link: () => {},
  index: 8,
  siteNav: false,
};

const MenuItems = [
  { key: Pages.Home, name: 'Home', link: Pages.Home, index: 1, siteNav: false },
  { key: Pages.Clock, name: 'Schedule', link: Pages.Clock, index: 2, siteNav: true },
  { key: Pages.Uv, name: 'UV', link: Pages.Uv, index: 3, siteNav: true },
  {
    key: Pages.LearnMore,
    name: 'Learn More',
    link: Pages.LearnMore,
    index: 5,
    siteNav: false,
  },
];

// Create a Context for the Page Provider
const PageContext = createContext();

function addItem(menuItemList, newItem) {
  if (!menuItemList.some((item) => item.key === newItem.key)) {
    menuItemList.push(newItem);
  }
}

function removeItem(menuItemList, keyToRemove) {
  const index = menuItemList.findIndex((item) => item.key === keyToRemove);
  if (index !== -1) {
    menuItemList.splice(index, 1);
  }
}

const SiteNavMenuItems = MenuItems.filter((item) => item.siteNav);
const SiteHamburgerMenuItems = [...MenuItems];

export const PageProvider = ({ children }) => {
  const [activePage, setActivePage] = useState(Pages.Home);
  const { isStandalone } = useBrowserInfo();
  const [debug, setDebug] = useState(false);
  const [siteNavItems, setSiteNavItems] = useState(SiteNavMenuItems);
  const [siteMenuItems, setSiteMenuItems] = useState(SiteHamburgerMenuItems);
  const { user } = useAuth();
  const { openModal } = useModal();
  const isFeatureActive = useFeature();

  useEffect(() => {
    if (isFeatureActive(FeatureFlags.CompassPageIsActive)) {
      addItem(siteNavItems, CompassItem);
      addItem(siteMenuItems, CompassItem);
    }
    if (isFeatureActive(FeatureFlags.DebugIsActive)) {
      const name = debug ? 'Debug Off' : 'Debug On';
      const link = () => {
        toggleDebug();
      };

      removeItem(siteMenuItems, ToggleDebugItem.key);
      addItem(siteMenuItems, { ...ToggleDebugItem, name, link });

      const showDebugLink = () => openModal(ModalTypes.Debug);
      addItem(siteNavItems, {
        ...ShowDebugItem,
        name: 'Debug Modal',
        link: showDebugLink,
      });
      addItem(siteMenuItems, {
        ...ShowDebugItem,
        name: 'Show Debug',
        link: showDebugLink,
      });
      addItem(siteMenuItems, VersionItem);
    }
  }, [user.id, debug]);

  // Function to navigate to a specific page
  const goToPage = (page) => {
    if (isValidPage(page)) {
      setActivePage(page);
    } else {
      setActivePage(page);
    }
  };

  // Function to get the current active page
  const getActivePage = () => {
    return activePage;
  };

  // Function to check if the page is valid
  const isValidPage = (page) => {
    return Object.values(Pages).includes(page);
  };

  // Function to check if the page is active
  const isActivePage = (page) => {
    return activePage.includes(page);
  };

  // Function to toggle debug mode on or off
  const toggleDebug = () => {
    toggleLogging();
    setDebug((prevDebug) => !prevDebug);
  };

  return (
    <PageContext.Provider
      value={{
        goToPage,
        getActivePage,
        isValidPage,
        isActivePage,
        toggleDebug,
        debug,
        siteNavItems,
        siteMenuItems,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

PageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use the PageContext
export const usePage = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};
