import { useMemo } from 'react';
import SunCalc from 'suncalc';

// -175: New Moon
// -87.5: Waxing Gibbous
// 0: First Quarter
// 87.5: Waxing Crescent
// 175: New Moon
// 262.5
// 350
// 437.5
// 525
//
export const MoonPhases = {
  FullMoon: 'Full Moon', // -175/525
  WaxingGibbous: 'Waxing Gibbous', // -87.5
  FirstQuarter: 'First Quarter', // 0
  WaxingCrescent: 'Waxing Crescent', // 87.5
  NewMoon: 'New Moon', // 175
  WaningCrescent: 'Waning Crescent', // 262.5
  LastQuarter: 'Last Quarter', // 350
  WaningGibbous: 'Waning Gibbous', // 437.5
};

const MoonPhaseValues = {
  [MoonPhases.NewMoon]: 0.0,
  [MoonPhases.WaxingCrescent]: 0.125,
  [MoonPhases.FirstQuarter]: 0.25,
  [MoonPhases.WaxingGibbous]: 0.375,
  [MoonPhases.FullMoon]: 0.5,
  [MoonPhases.WaningGibbous]: 0.625,
  [MoonPhases.LastQuarter]: 0.75,
  [MoonPhases.WaningCrescent]: 0.875,
};

function getMoonPhase(value) {
  // Normalize the value to be within 0 and 1 using the modular operator
  value = value % 1.0;
  if (value < 0) value += 1.0;

  const phases = Object.keys(MoonPhaseValues);
  for (let i = 0; i < phases.length; i++) {
    const currentPhaseValue = MoonPhaseValues[phases[i]];
    const nextPhaseValue = MoonPhaseValues[phases[i + 1]] || 1.0;
    if (value >= currentPhaseValue && value < nextPhaseValue) {
      return phases[i];
    }
  }
}

export function useMoonPhase(coordinates, displayDate) {
  const moonPhase = useMemo(() => {
    const { latitude, longitude } = coordinates;
    const { altitude, distance } = SunCalc.getMoonPosition(
      displayDate,
      latitude,
      longitude,
    );

    const { fraction, phase, angle } = SunCalc.getMoonIllumination(displayDate);

    const {
      rise: moonRise,
      set: moonSet,
      alwaysUp,
      alwaysDown,
    } = SunCalc.getMoonTimes(displayDate, latitude, longitude);

    const moonPhase = getMoonPhase(phase);
    return {
      altitude,
      distance,
      illumination: fraction,
      phaseName: moonPhase,
      phasePercent: MoonPhaseValues[moonPhase],
      angle,
      moonRise,
      moonSet,
      alwaysUp,
      alwaysDown,
    };
  }, [coordinates.latitude, coordinates.longitude, displayDate]);

  return moonPhase;
}
