import React from 'react';

import '../../css/Modal.css';
import { getSunTypeGradient, isDaySun } from '../../domain/sun-type';
import { SunTypes } from '../sun_path';

const Modal = ({
  show,
  onClose,
  darkTheme,
  sunType,
  children,
  allowClose = true,
  autoHeight,
}) => {
  if (!show) {
    return null;
  }

  const contentClass = darkTheme ? 'modal-content modal-dark' : 'modal-content';
  const modalCloseClass = darkTheme ? 'modal-close modal-dark' : 'modal-close';
  const gradient = getSunTypeGradient(sunType);
  const style = { background: gradient };
  if (autoHeight) {
    style.height = 'auto';
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className={contentClass} style={style} onClick={(e) => e.stopPropagation()}>
        {allowClose && (
          <button className={modalCloseClass} onClick={onClose}>
            &times;
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
