import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePage } from '../providers/page-provider';
import { SunriseSun } from '../icons/sun-svg-icon';
import { HeaderTop } from '../components/app_header';
import { SunTypes } from '../components/sun_path';
import { useBackground } from '../hooks/use-background-animation';
import { ModalTypes, useModal } from '../providers/modal-provider';
import { useAuth } from '../providers/auth-provider';

import '../css/Home.css';
import { sleep } from '../utils/async';
import useFeature, { FeatureFlags } from '../hooks/use-feature';
import { loadIntroDataFromCache, saveIntroDataToCache } from '../utils/local-storage';
import AddSubscriber from '../components/add-subscriber';

const markIntroAsSeen = () => {
  saveIntroDataToCache({ seen: true });
};

const isIntroSeen = async () => {
  const introData = await loadIntroDataFromCache();
  return introData?.seen;
};

const IntroMessage = ({ onClose }) => {
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const { isModalOpen } = useModal();
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    setTimeout(() => setVisible(true), 100);
  }, []);

  const goToNextPage = () => {
    setPage((prevPage) => (prevPage + 1) % 3 || 3);
  };

  return (
    <div className={`intro-container ${visible ? 'fade-in' : ''}`}>
      {(page === 1 || page < 1 || page > 3) && (
        <>
          <h2 className="intro-title">Without the sun, there is no life.</h2>
          <p className="intro-paragraph">
            Throughout history cultures have revered the sun, but today we are taught that
            the sun is dangerous. We are told to limit sun exposure, wear sunscreen, and
            use sunglasses, yet skin cancer rates are at an all time high. Over the past
            century, changes in work and lifestyle have brought us indoors to the point
            where, on average, we spend 90% of our time inside. So, is the sun really the
            problem?
          </p>
          <p className="intro-paragraph">
            At <strong>Sunlight is Life</strong>, we believe that the sun is our most
            powerful source of vitality. Only by embracing nature and harnessing the sun's
            energy can you truly thrive. The effects of sunlight are complex and
            powerful—small changes can lead to major improvements, and we will show you
            how.
          </p>
          <button
            className="gradient-button button-small button-shadow"
            onClick={goToNextPage}
          >
            Next
          </button>
        </>
      )}
      {page === 2 && (
        <>
          <h3 className="intro-subtitle">This app has three components:</h3>

          <div className="component-section">
            <div className="component-title">Schedule:</div>
            <div className="component-paragraph">
              A guide to the most important solar events throughout the day and how to
              strategically use sun exposure at these times to maximize its benefit.
            </div>
          </div>

          <div className="component-section">
            <div className="component-title">UV:</div>
            <div className="component-paragraph">
              Check the current UV index and compare it against your skin type to learn
              how long you can stay in the sun without having to worry about skin damage.
            </div>
          </div>

          <div className="component-section">
            <div className="component-title">Compass:</div>
            <div className="component-paragraph">
              Use the compass feature to locate where the sun will be during sunrise and
              sunset, so you always know where to look wherever you happen to be.
            </div>
          </div>

          <button
            className="gradient-button button-small button-shadow"
            onClick={goToNextPage}
          >
            Next
          </button>
        </>
      )}
      {page === 3 && (
        <>
          <div>
            <AddSubscriber
              isCodeRequired={!isAuthenticated || !user.id}
              onSuccess={onClose}
            />
          </div>
        </>
      )}
    </div>
  );
};

const DailyQuote =
  'Keep your face always toward the sunshine and shadows will fall behind you.';
const QuoteAuthor = 'Walt Whitman';

function HomePage({ onLoad, isLoaded }) {
  const { goToPage } = usePage();
  const { user } = useAuth();
  const isFeatureActive = useFeature();
  const [quote, setQuote] = useState(isLoaded ? DailyQuote : undefined);
  const [author, setAuthor] = useState(isLoaded ? QuoteAuthor : undefined);
  const [showIntroMessage, setShowIntroMessage] = useState(undefined);

  useEffect(() => {
    isIntroSeen().then((isSeen) => {
      if (isSeen) {
        setShowIntroMessage(false);
      } else {
        setShowIntroMessage(true);
      }
    });
  }, []);

  const sunriseAnimationClass = useMemo(() => {
    if (showIntroMessage === true) {
      return 'sunrise-intro-animation';
    }
    return 'sunrise-animation';
  }, [showIntroMessage]);

  useBackground(SunTypes.Twilight);

  const onLoadPageLoad = () => {
    onLoad();
    setQuote(DailyQuote);
    setAuthor('Walt Whitman');
  };

  useEffect(() => {
    if (!isLoaded) {
      sleep(500).then(onLoadPageLoad);
    }
  }, []);

  const { openModal } = useModal();
  const openSignUpModal = useCallback(() => openModal(ModalTypes.SignUp), []);

  function handleCloseIntro() {
    setShowIntroMessage(false);
    markIntroAsSeen();
  }

  return (
    <div className="App">
      <div className="header dark-mode-text-color">
        <HeaderTop
          darkTheme={true}
          sunType={SunTypes.Twilight}
          isLoaded={!showIntroMessage}
        />
      </div>
      <div className="content-container">
        {showIntroMessage && <IntroMessage onClose={handleCloseIntro} />}
        {!showIntroMessage && quote && (
          <>
            <div className="buttons-container">
              <button
                className="gradient-button button-large"
                onClick={() => goToPage('/clock')}
              >
                Schedule
              </button>
              <button
                className="gradient-button button-large"
                onClick={() => goToPage('/uv')}
              >
                UV
              </button>
              {isFeatureActive(FeatureFlags.CompassPageIsActive) && (
                <button
                  className="gradient-button button-large"
                  onClick={() => goToPage('/compass')}
                >
                  Compass
                </button>
              )}
              {!isFeatureActive(FeatureFlags.CompassPageIsActive) && (
                <button
                  className="gradient-button button-large"
                  onClick={() => goToPage('/learn-more')}
                >
                  Learn More
                </button>
              )}
            </div>
            <div className="quote-container">
              “{quote}” {author && <>&nbsp;&nbsp;―{author}</>}
            </div>
          </>
        )}
        <div className={`sunrise-container ${sunriseAnimationClass}`}>
          <SunriseSun radius={75} />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
