import React, { Component } from 'react';
import { SunTypes } from './sun_path';
import { displayBackgroundGradient } from '../hooks/use-background-animation';

import '../css/App.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidMount() {
    displayBackgroundGradient(SunTypes.Twilight);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  renderError() {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <h1>Something went wrong.</h1>
        {this.state.error && (
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
        )}
      </div>
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="App dark-mode-text-color">
          <div className="title">
            <h1>Sunlight is Life</h1>
          </div>
          <div className="page-content">{this.renderError()}</div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
