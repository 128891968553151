import React from 'react';

import NavigationMenu from './navigation-menu';
import { usePage } from '../providers/page-provider';

function SiteNav({ darkTheme, sunType }) {
  const { siteNavItems } = usePage();

  return (
    <NavigationMenu menuItems={siteNavItems} darkTheme={darkTheme} sunType={sunType} />
  );
}

export default SiteNav;
