import { DateTime } from 'luxon';
import logger from './logger';
import { DefaultLocationData } from '../domain/location';

export const DefaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const Today = DateTime.local();
const Tomorrow = Today.plus({ days: 1 }); // Add o

export const Days = {
  Today: (timezone) => DateTime.now().setZone(timezone || DefaultLocationData.timezone),
  Tomorrow: (timezone) =>
    DateTime.now()
      .setZone(timezone || DefaultLocationData.timezone)
      .plus({ days: 1 }),
};

export function getDateTime(date, timezone = DefaultTimeZone) {
  return date instanceof DateTime ? date : DateTime.fromJSDate(date).setZone(timezone);
}

export function getFormattedTime(date = DateTime.now()) {
  // Format with 2-digit minute, but without leading zero for single-digit hour
  return date.toLocaleString({
    hour: 'numeric', // Use 'numeric' to avoid leading zero for hours
    minute: '2-digit',
    hour12: true, // Use AM/PM format
    hourCycle: 'h12', // Ensures 12-hour format without leading zero
  });
}

export function formatDayTimeAndZone(dateTime) {
  // Desired format: "Wednesday May 4th 2024, 5:35 PM"
  const format = "MMMM d'th' yyyy, h:mm a ZZZZ";

  return ensureLuxonDateTime(dateTime).toFormat(format);
}

export function formatNamedDay(date = new Date(), timezone) {
  let luxonDate = getDateTime(date, timezone);

  // Desired format: "Wednesday May 4th 2024"
  const format = "EEEE MMMM d'th' yyyy";

  return luxonDate.toFormat(format);
}

export function formatDay(dateTime) {
  // Desired format: "Wednesday May 4th 2024, 5:35 PM"
  // Desired format: "Wednesday May 4th 2024"
  const format = "MMMM d'th' yyyy";

  return dateTime.toFormat(format);
}

export function formatShortDay(dateTime) {
  // Desired format: "4/4/24"
  const format = 'M/d/y';

  return dateTime.toFormat(format);
}

export function isValidDate(d) {
  return d instanceof DateTime && d.isValid && !d.invalid;
}

// Function to ensure an input is a Luxon DateTime
const ensureLuxonDateTime = (date) => {
  if (DateTime.isDateTime(date)) {
    return date; // Return if it's already a Luxon DateTime
  } else if (date instanceof Date) {
    return DateTime.fromJSDate(date); // Convert JS Date to Luxon DateTime
  } else {
    throw new Error('Input must be a JavaScript Date or Luxon DateTime object');
  }
};

// Function to check if two dates represent the same day
export const isDateSame = (date1, date2) => {
  const d1 = ensureLuxonDateTime(date1);
  const d2 = ensureLuxonDateTime(date2);

  return d1.year === d2.year && d1.month === d2.month && d1.day === d2.day;
};

export const isToday = (date, timezone) => {
  return isDateSame(date, Days.Today(timezone));
};

export const isDateTimeSame = (date1, date2) => {
  const d1 = ensureLuxonDateTime(date1);
  const d2 = ensureLuxonDateTime(date2);

  return (
    d1.year === d2.year &&
    d1.month === d2.month &&
    d1.day === d2.day &&
    d1.hour === d2.hour &&
    d1.minute === d2.minute
  );
};

export const isNow = (date, timezone) => {
  return isDateTimeSame(date, DateTime.now().setZone(timezone));
};

export function isTimeWithinTimeRange(date1, date2, limitInMinutes) {
  // Get the absolute difference in milliseconds between the two dates
  const timeDifference = Math.abs(date1 - date2);

  // Convert the given time limit to milliseconds
  const timeLimit = limitInMinutes * 60 * 1000;

  // Check if the time difference is within the specified limit
  return timeDifference <= timeLimit;
}

// Function to check if the date parts (year, month, day) are different
export function isSameDay(startTime, stopTime) {
  return (
    startTime.year === stopTime.year &&
    startTime.month === stopTime.month &&
    startTime.day === stopTime.day
  );
}

// Function to adjust stopTime to match the date of startTime
export function adjustDay(referenceDay, dayToAdjust) {
  if (isSameDay(referenceDay, dayToAdjust)) {
    return dayToAdjust;
  }

  return DateTime.fromObject({
    year: referenceDay.year,
    month: referenceDay.month,
    day: referenceDay.day,
    hour: dayToAdjust.hour,
    minute: dayToAdjust.minute,
    second: dayToAdjust.second,
    millisecond: dayToAdjust.millisecond,
  });
}

export function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = '';
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  if (remainingMinutes > 0) {
    result += `${hours > 0 ? ' ' : ''}${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
  }
  return result || '0 minutes'; // In case minutes is 0
}
